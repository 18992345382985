
.will-sign-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.will-sign-container .select-users-part{
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background-color: var(--backgroundColor2);
    overflow-y: auto;
}


.will-sign-container .multiSelectContainer .searchWrapper{
        border: none;
    padding: 0;
}
.will-sign-container .multiSelectContainer input{
    width: 100%;
    color: var(--textColor);
    background-color: var(--backgroundColor);
    height: 4vh;
}
.will-sign-container .multiSelectContainer .chip{
    font-size: .8vw;
    background: var(--backgroundColor);
    color: var(--textColor);
}
.will-sign-container #multiselectContainerReact > div.search-wrapper.searchWrapper > span > img{
    filter: invert(100%);
    -webkit-filter: invert(100%)
}
.will-sign-container .multiSelectContainer li{
    font-size: .8vw;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    display: flex;
    height: 3vh;
    padding: 0;
    padding-left: 1vw;
}

.will-sign-container .multiSelectContainer li:hover{
    background-color: #f0f0f0;
    color: var(--textColor);
}
.will-sign-container .notFound{
    font-size: .8vw;    
}
.will-sign-container .highlightOption{
    background-color: var(--backgroundColor);
}
.will-sign-container .select-users-part > div{
    width: 100%;
    height: 100%;
}
.will-sign-container .multiselect-tip{
    font-size: .8vw;
    color: var(--textColor);
}

.will-sign-container .open-to-sign-button{
    height: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}
.will-sign-container .open-to-sign-button:hover{
    opacity: .5;
}
.will-sign-container .open-to-sign-button .sign-ico{
    width: 2vw;
    height: fit-content;
}
