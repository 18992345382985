.organization-parent{
    width: 100%;
    height: 93vh;
}
.organization-parent .selectedOrganizationGroup-container{
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.organization-parent .selectedOrganizationGroup-label{
    margin-left: auto;
    font-size: .8vw;
    color: var(--textColor);
    font-weight: 200;
    margin-right: 1vw;
}
.organization-parent .selectedOrganizationGroup-select{
    min-width: 10vw;
    height: 4vh;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    margin-right: 1vw;
}
.organization-parent .newOrganizationGroup{
    background-color: var(--backgroundColor2);
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0vh 2.2vw;
    height: 4vh;
    line-height: 1;
    font-size: .7vw;
    font-weight: 200;
}
.newOrganizationGroup:hover{
    background-color: var(--goldColor);
    opacity: 1;
}
.organization-parent .newOrganizationGroup img{
    margin-right: 1vw;
    width: 1vw;
    height: fit-content;
}
.organization-parent .new-company-group-input{    
    margin-left: auto;
    width: 20%;
    height: 4vh;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
}
.organization-parent .confirmCompanyGroup {
    width: 1.5vw;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 0.5vw;
    cursor: pointer;
}
.organization-parent .cancelCompanyGroup {
    width: 1.5vw;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 0.5vw;
    cursor: pointer;
}
.organization-parent .cancelCompanyGroup:hover,
.organization-parent .confirmCompanyGroup:hover{
    opacity: .5;
}
.organization-parent .add-initial-organization{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 86vh;
}
.organization-parent .add-initial-organization img{
    display: flex;
    width: 1.5vw;
    height: fit-content;
    cursor: pointer;
}
.organization-parent .add-initial-organization img:hover{
    opacity: .5;
}
.organization-parent .organization-name-input{
    display: flex;
    width: 14vw;
    height: 4vh;
    border-radius: 10px;
    border: none;
    text-align: center;
    font-size: .8vw;
}