* {
  margin: 0;
  padding: 0;
  transition: width .5s, height .5s, top .5s, left .5s,  right .5s, bottom .5s, margin .5s, min-height .5s, color .5s, background-color .5s;
  -webkit-transition: width .5s, height .5s, top .5s, left .5s, right .5s, bottom .5s, margin .5s, min-height .5s, color .5s, background-color .5s;
}

:root{
  --backgroundColor: #e9eeff;
  --backgroundColor2: #fff;
  --backgroundColor3: #263644;
  --backgroundColor4: #ededed;
  --sidebarBackgroundColor: #263644;
  --sidebarHoverBackgroundColor: #1f2938;
  --textColor: #000;
  --textColor2: #fff;
  --scroolbarTrackColor: #e4e4e4;
  --scroolbarThumbColor: #4d4d4f;
  --boxShadowColor: #d6d6d6;
  --filterRatio: 0%;
  --tableRowHover: #c4d2ff;
  --importTableColor: #e4e4e4;
  --importTableColor2: #e9eeff;
  --frameWidth: 84vw;
  --leftFrame:0%;
  --goldColor: #edbb5f;
  --langBackgroundColor: #ebebeb;
  --tableHeaderColor: #285cb0;
  --confirmColor: rgb(102, 173, 90);
  --cancelColor: rgb(219, 78, 82);
} 
input[type=radio]{
  accent-color: #000!important;
}
*::-webkit-scrollbar {
  height: 0px!important;
  width: 0px!important;
}

*::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  background-color: #4d4d4f;
  border-radius: 100px;
}

input[type="radio"] {
  cursor: pointer;
}

input[type="text"] {
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--sidebarBackgroundColor);
  text-align: center;
  border: none;
  color: #fff;
  border-radius: 5px;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none; 
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #ccc;
  border-radius: 16px;
}

input[type="range"]::-moz-range-track {
  background: #ccc;
  border-radius: 16px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none; 
  height: 15px;
  width: 15px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #000;
  box-shadow: -807px 0 0 800px #000;
}


input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  box-shadow: -807px 0 0 800px #000;
}

input[type="month"]{
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--backgroundColor);
  text-align: center;
  border: none;
  color: var(--textColor);
  width: 100%;
  height: 4vh;
  border-radius: 5px;
}

.nav-root {
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-root .logo-box {
  width: 2.5vw;
  height: fit-content;
  border-radius: 7px;
  background-color: var(--backgroundColor3)!important;
  padding: 1vh .5vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-root .logo-box .logo-box-ico{
  width: 100%;
  height: 100%;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
.nav-root .info-box{
  display: flex;
  flex-flow: row wrap;
  margin-right: auto;
  height: 5vh;
  align-items: center;
}
.nav-root .text-box {
  padding-left: 1vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.nav-root .text-box label{
    line-height: 1.5vh;
}
.search-item{
  width: 15vw;
  height: 4vh;
  border-radius: 10px;
  padding: 0 1vw;
  border: none;
  background-color: var(--backgroundColor2);
  color: var(--textColor);
  font-size: .8vw;
}

select{
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--sidebarBackgroundColor);
  text-align: center;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

tbody tr{
  cursor: pointer;
}
button{
  cursor: pointer;
}
input[type="checkbox"]{
  cursor: pointer;
}
.tooltip-container {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
} 
.tooltip-content {
  position: absolute;
  background-color: var(--sidebarBackgroundColor);
  color: white;
  padding: .5vh .5vw;
  border-radius: 5px;
  display: none;
  font-size: .7vw;
  text-align: center;
  z-index: 999;
}
.tooltip-trigger:hover + .tooltip-content {
  display: flex;
}

input.PhoneInputInput{
  background-color: transparent;
  border: none;
  outline: none;
}

