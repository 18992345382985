.companies-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.companies-main-container .company-group-box-parent {
    max-height: 86vh;
    height: min-content;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2vh;
}

.companies-main-container .company-detail-container{
    width: 0%;
    height: 100%;
    display: flex;
    transition: all .0s!important;
    -webkit-transition: all .0s!important;
}