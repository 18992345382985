.import-parent *{
  font-size: 0.7vw;
}
.import-parent{
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
}
  .import-container{
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: row;
  }
  .import-container .box-parent{
      background-color: var(--backgroundColor2);
    min-height: 23%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    border-radius: 5px;
    padding: 2vh 1vw;
    justify-content: space-between;
  }
  .import-container .box-parent .header-1{
    color: #feb64c;
    font-weight: 600;
    font-size: 1vw;
    line-height: 1;
    display: flex;
  }
  .import-container .box-parent .explain{
    font-size: 0.7vw;
    line-height: 2vh;
    color: var(--textColor);
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  .upload-btn {
    font-size: 0.7vw;
    border-radius: 5px;
    border: none;
    font-weight: normal;
    background-color: #feb64c;
    color: var(--textColor);
    width: 100%;
    margin-top: 2vh;
    margin-left: auto;
    height: 4vh;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .upload-btn:hover {
    opacity: .5;
  }
  .upload-input{
    font-size: 0.7vw;
    border-radius: 5px;
    border: none;
    font-weight: normal;
    background-color: #445b6e;
    color: white;
    width: 100%;
    height: 20%;
  }
  .upload-input:hover{
    opacity: .5;
  }
  .import-container .table-container{
    margin-left: 2%;
    width: 75%;
    height: 100%;
  }
  .import-column-1{
    margin-left: 5%;
    width: 18%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
  }
  .select-sla-container{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    background-color: var(--backgroundColor2);
    border-radius: 5px;
    padding: 2vh 1vw;
    justify-content: space-between;
  }
  .select-sla-container .label-import-1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #feb64c;
    font-size: 1vw;
    font-weight: 600;
    line-height: 1;
}
.select-sla-container select{
  font-size: 0.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #feb64c;
  text-align: center;
  border: none;
  color: var(--textColor);
  width: 100%;
  height: 4vh;
  border-radius: 5px;
}
input#file{
  font-size: 0.7vw;
  width: 100%;
}

.loader {
  border: 0.15vw solid #f3f3f3; /* Light grey */
  border-top: 0.15vw solid #3498db; /* Blue */
  border-radius: 50%;
  width: 1vw;
  height: 1vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

