/* CSS */
.answer-button-parent .answer-button {
    background-color: transparent;
    border: 1px solid #ffca7b;
    border-radius: 20px;
    color: #ffca7b;
    cursor: pointer;
    outline: none;
    padding: 1vh 1vw;
    margin-right: .5vw;
    margin-top: 1vh;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    display: inline-block;
    box-sizing: content-box;
    width: Auto;
    font-size: 0.7vw;
    max-width: 100%;
    word-break: break-all; /* Uzun kelimeleri kır */
  white-space: pre-wrap; /* Boşlukları koru, otomatik olarak alt satıra geç */
}

.answer-button-parent .answer-button:disabled {
    pointer-events: none;
}

.answer-button-parent .answer-button:hover {
    color: var(--textColor);

    background-color: #feb64c;
    border-color: rgb(0, 0, 0);
}

.answer-button-parent .answer-button:focus {
    color: var(--textColor);
    background-color: #feb64c;
}

.answer-button-parent .answer-button-active {
    color: var(--textColor);

    background-color: #feb64c;
    pointer-events: none;
}

.answer-button-parent .input-add-answer {
    border: 1px solid var(--backgroundColor3);
    border-radius: 20px;
    cursor: pointer;
    padding: 0.5vh 0;
    margin: 0;
    background: transparent;
    color: #feb64c;
    min-width: 6vw;
    max-width: 100%;
    text-align: center;
    line-height: 100%;
    margin-right: .5vw;
    margin-top: 1vh;
    font-size: .7vw;
}

.answer-button-parent .input-add-answer:focus {
    color: var(--textColor);
    outline: none;
}

.answer-button-parent .input-add-answer:hover {
    color: var(--textColor);
    background-color: #feb64c;
}