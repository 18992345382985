    
  .sliding-and-userdropdown-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
    margin-left: auto;
  }
  .sliding-and-userdropdown-container .dropdown-user-selection-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    text-align: center;
  }
  
  .sliding-and-userdropdown-container .dropdown__button {
    width: 9vw;
    padding: 0.5vw;
    position: relative;
    font-size: 0.9vw;
    user-select: none;
    border-radius: 5px;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    border: none;
    cursor: pointer;
    text-align: left;
    transition: all 0.5s ease;
  }
  
  .sliding-and-userdropdown-container .dropdown__button:hover {
    opacity: .5;
  } 
  
  .sliding-and-userdropdown-container .dropdown__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    padding: .5vw;
    width: 100%; /* Set width to 100% */
    background-color: var(--backgroundColor2);
      color: var(--textColor);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    box-sizing: border-box;
  }
  
  .sliding-and-userdropdown-container .dropdown__menu.show {
    opacity: 1;
    visibility: visible;
  }
  
  .sliding-and-userdropdown-container .dropdown__menu-item {
    padding: .5vw 1vw .5vw 1vw;
    cursor: pointer;
    font-size: 0.8vw;
    border: none!important;
    border-bottom: 1px solid #ddd!important;
    transition: all 0 ease!important;
    width: 100%; /* Set width to 100% */
    box-sizing: border-box;
  }
  
  .sliding-and-userdropdown-container .dropdown__menu-item:last-child {
    border-bottom: none!important;
  }
  
  .sliding-and-userdropdown-container .dropdown__menu-item.active {
    opacity: .25;
  }
  
  .sliding-and-userdropdown-container .dropdown__menu-item:hover {
    opacity: .5;
  }
  
  .sliding-and-userdropdown-container .dropdown__submenu {
    position: absolute;
    top: 0;
    left: auto;
    right: 100%;
    z-index: 2;
    width: calc(100% - 20px);
    padding: 1em;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
    transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
    border-radius: 5px;
    width: 100%; /* Set width to 100% */
    font-size: 0.9vw;
    box-sizing: border-box;
  }
  
  .sliding-and-userdropdown-container .dropdown__submenu.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  
  .sliding-and-userdropdown-container .dropdown__submenu-item {
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.5s ease;
    border-bottom: 1px solid #ddd;
    width: 100%; /* Set width to 100% */
    box-sizing: border-box;
    font-size: 0.9vw;
  }
  
  .sliding-and-userdropdown-container .dropdown__submenu-item:last-child {
    border-bottom: none;
  }
  
  .sliding-and-userdropdown-container .dropdown__submenu-item:hover {
    opacity: .5;
  }
  
  .sliding-and-userdropdown-container .dropdown__submenu-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
    border-radius: 2px;
  }
  
  .sliding-and-userdropdown-container .dropdown__submenu-item:last-child .dropdown__submenu-icon {
    display: none;
  }
  
  .sliding-and-userdropdown-container .sliding-bar-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }
  
  .sliding-and-userdropdown-container .toggle-button {
    height: 4vh;
    width: 9vw;
    border-radius: 5px;
    border: none;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    font-size: 0.9vw;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
  }
  
  .sliding-and-userdropdown-container .toggle-button:hover {
    opacity: .5;
  }
  
  .sliding-and-userdropdown-container .sliding-bar {
    height: 4vh;
    width: 0;
    overflow: hidden;
    transition: width 0.5s ease-out;
    display: flex;
    border-radius: 5px;
    border: 1px solid #a7a1ae;
  }
  
  .sliding-and-userdropdown-container .sliding-bar.open {
    display: flex;
    justify-content: space-between;
    width: 61vw;
    transition-delay: 0.5s;
    border: 1px solid #a7a1ae;
    border-radius: 5px;
    overflow-x: auto;
    padding-left: 1vw;
  }
  .sliding-and-userdropdown-container .sliding-bar button {
    height: 100%;
    width: 33.5%;
    border: none;
    background-color: #f4f7ff;
      color: var(--textColor);
;
    font-size: 0.9vw;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
  }
  
  .sliding-and-userdropdown-container .sliding-bar button:hover {
    opacity: .5;
  }

  .sliding-and-userdropdown-container .container-checkbox-chart{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
      color: var(--textColor);
;
    font-size: .8vw;
    white-space: nowrap;

}

.sliding-and-userdropdown-container .container-checkbox-chart .checkmark{
    margin: 1vw;
}
.sliding-and-userdropdown-container .container-checkbox-chart input[type="checkbox"]{
    margin-left: .5vw;
}
.sliding-and-userdropdown-container .dashboard-customer-name{
  height: 2.5vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--textColor);
  font-size: 1vw;
  font-weight: 600;
  position: absolute;
  left: 0vw; 
}
.dashboard-customer-name img{
  display: flex;
  height: 100%;
  width: fit-content;
}