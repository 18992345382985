.parent-container-all-kpis {
  width: 100%;
  height: 93vh;
  display: flex;
  flex-direction: column;
}
.parent-container-all-kpis .selected-comp-label{
  font-size: .8vw;
  color: var(--textColor);
  font-weight: 200;
  margin-right: 1vw;
}
.parent-container-all-kpis select {
  min-width: 10vw;
  height: 4vh;
  border-radius: 10px;
  background-color: var(--backgroundColor2);
  color: var(--textColor);
  margin-right: 1vw;
}
.header-1 {
  line-height: 1em;
  color: #fff;
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 0%;
  font-weight: bold;
  text-align: center;
}

.container-all-kpis thead {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 6vh;
  height: 6vh;
  justify-content: center;
  padding-left: 1vw;
  padding-right: 1vw;
}


.container-all-kpis thead tr {
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
  background-color: var(--backgroundColor2);
  min-height: 6vh;

}

.container-all-kpis tbody {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: #eee;
  display: block;
  width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;  
}
.container-all-kpis tbody tr{
  width: 100%;
  border-radius: 5px;
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 4vh;
  max-height: 4vh;
}

.container-all-kpis {
  text-align: left;
  width: 90%;
  background-color: var(--backgroundColor2);
  border-radius: 5px 5px 5px 5px;
  min-height: 82vh;
  height: 82vh;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.container-all-kpis-side {
  height: 38vh;
  min-height: 38vh;
}

.container-all-kpis th {
  font-weight: 400;
  font-size: 0.8vw;
  text-align: left;
  color: var(--tableHeaderColor);
  display: flex;
  flex-direction: row;
  padding-left: 1%;
  background-color: var(--backgroundColor2);
  align-items: center;

}

.container-all-kpis td{
  font-size: .7vw;
  font-weight: 200;
  color: var(--textColor);
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  align-items: center;
  padding-left: 1%;
  overflow: visible;
}


.container-all-kpis img {
  display: flex;
  width: 1vw;
  height: fit-content;
}
.container-all-kpis th:nth-child(1),
.container-all-kpis td:nth-child(1) {
  width: 35%;

}
.container-all-kpis td:nth-child(1) label{
  display: flex;
  width: 60%;
  white-space: nowrap;
  text-overflow: ellipsis;
  
}
.container-all-kpis th:nth-child(2),
.container-all-kpis td:nth-child(2) {
  width: 25%;
}
.container-all-kpis th:nth-child(3),
.container-all-kpis td:nth-child(3) {
  width: 15%;
}
.container-all-kpis th:nth-child(4),
.container-all-kpis td:nth-child(4) {
  width: 25%;
}
.container-all-kpis th:nth-child(5),
.container-all-kpis td:nth-child(5){
  width: 5%;
}




.container-all-kpis tr:hover {
  background-color: var(--tableRowHover);
  transition-delay: 0s;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: line;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}

.square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}

.customer-name {
  width: max-content;
  box-sizing: border-box;
  font-size: 1.2vw;
  text-align: left;
  font-weight: 800;
  margin: 1%;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: auto;
}


.customer-name-slas-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  border-style: solid;
  padding: 0.5rem 2rem;
  border-color: #000;
  border-width: 1px;
  border-radius: 5px;
}
.all-kpi-detail-parent {
  width: 90%;
  height: 41vh;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  margin-left: 5%;
}
.all-kpi-detail-parent-side {
  margin-top: 50%;
}

.checkboxes-on label:first-child {
  margin-top: 3.5vh;
}
.checkboxes-on label:hover {
  background-color: var(--tableRowHover);
}

.kpis-chart-parent {
  display: flex;
  flex-direction: column;
  width: 12%;
  height: 80%;
}

.kpis-chart {
  width: 100%;
  height: 95%;
  margin-bottom: 1%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: var(--backgroundColor2);
  padding-top: 10%;
  padding-bottom: 10%;
}
.kpis-chart-side {
  height: 0%;
}
.yearly-trend-parent-box-kpis {
  display: flex;
  flex-flow: column;
  height: max-content;
  gap: 1rem;
  width: 100%;
}

.yearly-trend-parent-box-kpis .trend-container {
  display: flex;
  flex-direction: column;
    color: var(--textColor);
;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 0.7vw;
  width: 100%;
}
.yearly-trend-parent-box-kpis .month {
  text-align: center;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 5px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.yearly-trend-parent-box-kpis .month:hover {
  background-color: var(--tableRowHover);
  color: var(--textColor);

  font-weight: normal;
  font-size: 1vw;
  border-radius: 5px;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: all;
  transition-timing-function: line;
}
.yearly-trend-parent-box-kpis .month-triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}
.yearly-trend-parent-box-kpis .month-triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}
.yearly-trend-parent-box-kpis .month-square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}

.kpis-chart-parent .sla-trend-label {
  text-align: center;
    color: var(--textColor);
  font-weight: bold;
  height: 5%;
  font-size: 1vw;
}
.filter-parent{
  display: flex;
  max-height: 4vh;
  width: auto;
}
.filter-container{
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.checkbox-dropdown {
  width:10vw;
  padding: 0vh 1vw;
  position: relative;
  font-size: 0.8vw;
  user-select: none;
  border-radius: 10px;
  background-color: var(--backgroundColor2);
  display: flex;
  align-items: center;
  color: var(--textColor);
  height: 4vh;
  margin-left: 1vw;
}

.input-weight-value-container{
  width:20vw;
  border-radius: 5px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-weight-value-container label{
  font-size: 0.8vw;
  font-weight: 200;
  color: var(--textColor);
  display: flex;
  align-items: center;
}
.input-weight-value-container input[type="number"]{
  width: 5vw;
  border-radius: 10px;
  border: none;
  text-align: center;
}

/* Display CSS arrow to the right of the dropdown text */
.checkbox-dropdown:after {
  content:'';
  height: 0;
  position: absolute;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #000;
  top: 50%;
  right: .5vw;
  margin-top: -.3vh;
}

.is-active{
  border-radius: 5px 5px 0px 0px;
}
/* Reverse the CSS arrow when the dropdown is active */
.checkbox-dropdown.is-active:after {
  border-bottom-color: #000;
  border-top-color: #fff;
  margin-top: -.9vh;

}

.checkbox-dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%; /* align the dropdown right below the dropdown text */
  border: none;
  right: 0px; /* align the dropdown to the right */
  opacity: 0; /* hide the dropdown */  
  overflow: scroll;
  overflow-x: hidden;
  pointer-events: none; /* avoid mouse click events inside the dropdown */
  width: 10vw;
}


.is-active .checkbox-dropdown-list {
  opacity: 1; /* display the dropdown */
  pointer-events: auto; /* make sure that the user still can select checkboxes */
}

.checkbox-dropdown-list li label {
  display: block;
  padding: .5vh 1vw;
  background-color: rgb(87, 87, 87);
  display: flex;
  flex-direction: row;
  color: #fff;
}

.checkbox-dropdown-list li label:hover {
  background-color: var(--backgroundColor3)!important;
  color: white;
}

.copy-button{
  margin: 0;
  padding: 0;
  width: 1vw;
  height: fit-content;
}
.copy-button:hover{
  opacity: .5;
}
.parent-container-all-kpis .add-button {
  width: 1vw;
  height: fit-content;
}
.parent-container-all-kpis .add-button:hover {
  opacity: 0.5;
}

.parent-container-all-kpis .add-kpi-parent {
  display: flex;
  width: 100%;
  min-height: 0vh;
  background-color: var(--backgroundColor);
  z-index: 1;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  margin-bottom:1vh;
  padding-left: 1%;
  max-height: 7vh;
}
.parent-container-all-kpis .add-kpi-parent:hover{
  background-color: var(--tableRowHover);
}
.parent-container-all-kpis .add-kpi-parent td{
  width: 100%;
  height: 100%;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.parent-container-all-kpis .add-kpi-parent td .up-arrow-button {
  border: none;
  background-color: transparent;
  width: 1vw;
  padding: 0;
  margin: 0;
  margin-right: 1vw;
}

.parent-container-all-kpis .add-kpi-parent td .up-arrow{
  width: 100%;
  height: fit-content;
  display: flex;
}
.up-arrow:hover{
  opacity: .5;
}
.parent-container-all-kpis .add-kpi-parent td .copy-kpi-button{
  display: flex;
  flex-direction: row;
  border: 0.8px solid #000;
  padding: 0.8vh 2.2vw;
  border-radius: 10px;
  background-color: var(--backgroundColor2);
  color: var(--textColor);
  transition: all .2s!important;
  font-weight: 200;
  font-size: .7vw;
  margin-left: auto;
  margin-right: 1vw;
}
.parent-container-all-kpis .add-kpi-parent td .copy-kpi-button:hover{
  background-color: var(--goldColor);
}
.parent-container-all-kpis .companyNameTitle-italic {
  font-style: italic;
  font-size: .7vw;
  margin-right: .5vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--textColor);
  width: auto!important;
}
.parent-container-all-kpis .companyNameTitle-bold {
  font-weight: 500;
  font-size: .7vw;
  margin-right: .5vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--textColor);
  width: auto!important;
}

.parent-container-all-kpis .add-kpi-parent-content {
  display: flex;
  align-items: center;
  margin-left: .5vw;
  flex-direction: row;
  width: 70%;
  gap: .5vw;
  overflow-x: auto;
  overflow-y: auto;
}
.parent-container-all-kpis .add-kpi-parent-content::-webkit-scrollbar {
  height: 2px!important;
}
.parent-container-all-kpis .copy-kpi{
  width: 1vw;
  height: fit-content;
  display: flex;
  margin-right: 1vw;
}
.parent-container-all-kpis .copy-kpi:hover{
  opacity: .5;
}