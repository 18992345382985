.services-parent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  left: 0vw;
}

.services-parent .nav-root{
  width: 90%;
}

.services-parent .container-services {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 82%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow-x: auto;
  overflow-y: auto;
  background-color: var(--backgroundColor2);
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  margin-left: 5%;
  margin-right: 5%;
  padding-left: 2%;
}


.services-parent .container-services thead {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 5px;
  width: 100%;
}

.services-parent .container-services thead th {
  text-align: center;
  background-color: var(--backgroundColor2);
  height: 4vh;
  font-size: .8vw;
  font-weight: 400;
  color: var(--tableHeaderColor);
  display: flex;
  align-items: center;
  justify-content: center;
}



.services-parent .container-services tbody {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 5px;
}

.services-parent tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 3vh;
  height: auto;

}

.services-parent .container-services tbody tr {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-bottom: 1vh;
  border-radius: 5px;
  background-color: var(--backgroundColor);
  min-height: 4vh;
  position: relative;
}

.container-services tr td,
.container-services tr th {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--textColor);
  width: 15vw;
  height: auto;
  word-wrap: break-word;
  font-size: .7vw;
  vertical-align: middle;
  gap: 1vw;
}


.services-parent .container-services tbody tr:hover {
  background-color: var(--tableRowHover);
  transition-delay: 0s;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: line;
}

.services-parent .container-services tbody .icon {
  width: 1vw;
  height: fit-content;
}

.services-parent .container-services tbody .edit-service-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  
}

.services-parent .add-service-img {
  width: 1vw;
  height: fit-content;
  margin-right: 1vw;
}

.services-parent .add-service-button {
  background-color: var(--backgroundColor2);
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 4vh;
  line-height: 1;
  font-size: .7vw;
  font-weight: 200;
  margin-right: 1vw;
}
.services-parent .add-service-button:hover{
  background-color: var(--goldColor);
  opacity: 1;
}