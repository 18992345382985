.edit-sla-metadata-container{
    display: flex;
    flex-direction: column;
    padding: 1vw 1vw;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    gap: 1vh;
}
.edit-sla-metadata-container .table-header{
    width: 100%;
    text-align: center;
    text-align: center;
    color: var(--tableHeaderColor);
    font-weight: 400;
    font-size: .8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-sla-metadata-container .edit-item {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}
.edit-sla-metadata-container .date-selection{
    flex-direction: row;
    gap: 1vw;
}
.edit-sla-metadata-container .date-selection .date-input-container{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto
}

.edit-sla-metadata-container .edit-item label {
    font-size: .7vw;
    color: var(--textColor);
}
.edit-sla-metadata-container .edit-item input[type="text"] {
    height: 4vh;
    color: var(--textColor);
    background-color: var(--backgroundColor);
    font-size: .7vw;
    width: 100%;
}
.edit-sla-metadata-container .edit-item select {
    height: 4vh;
    color: var(--textColor);
    background-color: var(--backgroundColor);
    font-size: .7vw;
}
.edit-sla-metadata-container .edit-item .searchWrapper{
    border: none;
    padding: 0;
  }
  .edit-sla-metadata-container .edit-item .option{
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    font-size: .8vw;
  }
  .edit-sla-metadata-container .edit-item  .multiSelectContainer li:hover{
    background-color: #f0f0f0;
    color: var(--textColor);
  }
  .edit-sla-metadata-container .edit-item .multiSelectContainer li{
    padding: .2vw;
  }
  .edit-sla-metadata-container .edit-item .chip{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-color: var(--backgroundColor);
    color: var(--textColor);
  }
  .edit-sla-metadata-container .edit-item .closeIcon{
    filter: invert(100%);
  }
  .edit-sla-metadata-container .button-container{
    margin-top: auto;
  }

  .edit-sla-metadata-container .confirm-button{
    width: 15vw;
  }