.kpi-detail-container{
    width: 100%;
    height: 100%;
      background-color: var(--backgroundColor2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 0% 0% 0% 2%;
    box-shadow: 3px 3px 2px var(--boxShadowColor);
}
.kpi-detail-container-part1{
  width: 100%;
  height: 6vh;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}
.kpi-detail-container-part1-column{
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-Word;
  height: 100%; 
  justify-content: space-around;
}
.kpi-detail-container-part1-column-item{
    color: var(--textColor);
  text-align: center;
  overflow-wrap: break-Word;
  font-size: 0.7vw;
}
.kpi-detail-container-part1-column-item:nth-child(2){
  border-radius: 5px;
  padding: 0vh 1vw;
  background-color: #fcb459;

}

.kpi-detail-container-part1-column-edit-button-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.kpi-detail-container-part1-column-edit-button{
  border: none;
  background-color: transparent;
  margin-left: auto;
  display: flex;

}
.kpi-detail-container-part1-column-edit-button img{
  width: 1vw; 
  height: fit-content;
}
.kpi-detail-container-part1-column-edit-button:hover{
  opacity: 0.5;
  
}

.kpi-detail-container-part2{
  width: 100%;
  height: 34vh;
    background-color: var(--backgroundColor2);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.kpi-detail-container-part2 table{
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor2);
  display: flex;
  flex-direction: column;
  border-radius: 5px;

}

.kpi-detail-container-part2 thead{
  height: 6vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.kpi-detail-container-part2 thead tr{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-right: 2% ;
}

.kpi-detail-container-part2 thead th{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  padding-left: 0;
  text-align: center;
  font-size: 0.7vw;
  color: var(--tableHeaderColor);
  font-weight: 200;
}

.kpi-detail-container-part2 tbody td{
  width: 10%;
}

.kpi-detail-container-part2 tbody td:nth-child(8),
.kpi-detail-container-part2 thead th:nth-child(8){
  width: 20%;
}


.kpi-detail-container-part2 tbody{
  height: auto;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.kpi-detail-container-part2 tbody tr{
   background-color: var(--backgroundColor);
  display: flex;
  flex-direction: row;
  width: 98%;
  min-height: 4vh;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 1vh;
  cursor:default
}

.kpi-detail-container-part2  tbody tr:hover{
  background-color: var(--tableRowHover);
  transition-delay: 0s;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: line;
}

.kpi-detail-container-part2 tbody td{
    color: var(--textColor);
  display: flex;
  justify-content: center;
  width: 10%;
  font-size: 0.7vw;
}

.kpi-detail-tbody .triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}

.kpi-detail-tbody .triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}

.kpi-detail-tbody .square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}
