.dashboard-parent{
    height: 93vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dashboard-parent .chart-select-container{
    height: 4vh;
    width: 96%;
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dashboard-parent .chart-select-container .selected-comp-label{
    font-size: .8vw;
    color: var(--textColor);
    font-weight: 200;
    margin-right: 1vw;
    margin-left: 1vw;
}
.dashboard-parent .chart-select-container select{
    width: 10vw;
    height: 4vh;
    border-radius: 10px;
    margin-right: 1vw;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
}
.dashboard-parent .counter-boxes-container{
    display: flex;
    flex-direction: row;
    height: 13vh;
    width: 96%;
    gap: 2%;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.dashboard-parent .charts-container{
    width: 96%;
    height: 68vh;
    display: flex;
    flex-direction: row;
    gap: 1vw;
}
.dashboard-parent .charts-container .aggrement-statics-container{
    width: 40%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    padding: 2vh 1vw;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}
.dashboard-parent .charts-container .statistics-by-company-container{
    width: 60%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2%;
}
.dashboard-parent .charts-container .statistics-by-company-container .line-chart-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 49%;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    padding: 2vh 1vw;
    gap: 2vh;
}
.dashboard-parent .charts-container .statistics-by-company-container .warnings-list-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 49%;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    padding: 2vh 1vw;
    gap: 2vh;
}
.statistics-by-company-container .warnings-list-container .warning-table-container{
    display: flex;
    width: 100%;
    height: 90%;
}
.statistics-by-company-container .line-chart-container .bar-chart-container{
    display: flex;
    width: 100%;
    height: 100%;
}
.dashboard-parent .charts-container .title{
    text-align: center;
    color: var(--tableHeaderColor);
    font-weight: 400;
    font-size: .8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboard-parent .charts-container .horizontalBarChart-container{
    display: flex;
    height: 100%;
}