
.box-cb {
    width: 23.5vw;
    height: 100%;
    color: #fff;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    padding-bottom: 0vh;
    box-shadow: 3px 3px 2px var(--boxShadowColor);
}
.box-cb-wide {
  width: 66vw;
  height: 100%;
  color: #fff;
  border-radius: 10px;
  background: var(--backgroundColor2);;
  padding-bottom: 0vh;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
}

.box-cb-normal {
  width: 45vw;
  height: 100%;
  color: #fff;
  border-radius: 10px;
  background: var(--backgroundColor2);
  padding-bottom: 0vh;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
}

.navbox {
    width: 100%;
    height: 12.5%;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    background-color: #feb64c;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.navbox .refresh_ico{
  position: absolute;
  width: 1.3vw;
  height: fit-content;
  right: 1vw;
  top: 50%;
  transform: translate(-50%,-50%);
}
.navbox .refresh_ico:hover{
  opacity: .5;
  cursor: pointer;
}





  .box-cb .navbox label{
      color: var(--textColor);
    font-weight: 600;
    font-size: 0.8vw;
  }
  .box-cb .navbox div div label:nth-child(1){
    color: #2ab300;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 1vh;
  }
  .box-cb .navbox div div label:last-child{
      color: var(--textColor);
    font-weight: 200;
    font-size: 0.7vw;
  }
  

  .box-cb-wide .navbox label{
      color: var(--textColor);
    font-weight: 600;
  }
  .box-cb-wide .navbox div div label:nth-child(1){
    color: #2ab300;
    font-size: 0.8vw;
    font-weight: 600;
    line-height: 1vh;
  }
  .box-cb-wide .navbox div div label:last-child{
      color: var(--textColor);
    font-weight: 200;
    font-size: 0.7vw;
  }
  .box-cb-normal .navbox label{
      color: var(--textColor);
    font-weight: 600;
  }
  .box-cb-normal .navbox div div label:nth-child(1){
    color: #2ab300;
    font-size: 0.8vw;
    font-weight: 600;
    line-height: 1vh;
  }
  .box-cb-normal .navbox div div label:last-child{
      color: var(--textColor);
    font-weight: 200;
    font-size: 0.7vw;
  }
  
 


.disable{
  pointer-events: none;
}
.able{
  pointer-events: all;
}
.goog-te-gadget .goog-te-combo{
  width: auto;
  background-color: var(--backgroundColor2);
}