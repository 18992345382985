.notifications-parent{
    position: relative;
    width: 1.5vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.notifications-parent .notification-counter{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: .75vw;
    font-weight: 600;
    background-color: var(--cancelColor);
    color: black;
    position: absolute;
    top: -.5vh;
    left: 1vw;
    width: min-content;
    height: 1vw;
    z-index: 999;
    padding: 0 .2vw;
}
.notifications-parent .notifications-ico{
    width: 100%;
    height: fit-content;
    filter: invert(var(--filterRatio));
    -webkit-filter: invert(var(--filterRatio));
    position: relative;
}
.notifications-parent > .notifications-ico:hover{
    opacity: .5;
}

.notifications-parent .notifications-list{
    max-height: 91vh;
    width: 22vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: transparent;
    position: fixed;
    top: 9vh;
    right: 1vw;
    z-index: 999;
    border-radius: 10px;
    gap: 1vh;
    animation: slideHeight 0.5s ease-in-out;
}

@keyframes slideHeight {
    0% {
        height: 0;
    }

    100% {
        height: 100%; /* Bitiş yüksekliği 100% olarak ayarlandı */
    }
}
.notifications-parent .notifications-parent.closed {
    animation: slideHeightClose 0.5s ease-in-out;
}

@keyframes slideHeightClose {
    0% {
        height: 100%;
    }

    100% {
        height: 0; /* Bitiş yüksekliği 0 olarak ayarlandı */
    }
}