.forecast-configuration-container {
    width: 100%;
    height: 100%;
}

.forecast-configuration-container .auto-selection-tab {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.forecast-configuration-container .auto-selection-tab input[type="radio"] {
    margin-right: 0.5vw;
}

.forecast-configuration-container .auto-selection-tab label {
    margin-right: 2vw;
}

.forecast-configuration-container .auto-selection-tab .selected {
    font-weight: 900;
}

.forecast-configuration-container .target-selection-tab {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.target-selection-tab .active {
    border-style: solid !important;
    border-width: .1vw;
}

.target-selection-tab .data-list {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    width: 95%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 5px;
    padding: 1.5vh 0vw 1.5vh 0vw;
    box-sizing: border-box;
    overflow-y:auto ;
    justify-content: flex-start;
    align-items: center;
    gap: 6% 2%; 
    color: var(--textColor);
}
.target-selection-tab .data-list::-webkit-scrollbar {
    display: none !important;
}

.target-selection-tab  .data-list .data-list-item {
    display: flex;
    flex-direction: column;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    border-radius: 5px;
    width: 15%;
    height: 35%;
    align-items: center;
    justify-content: center;
    font-size: .7vw;
    padding: 1vh 1vw 1vh 1vw;
    box-sizing: border-box;
    transition: all 0s!important;
    -webkit-transition: all 0s!important;
    transition: background-color .5s, color .5s!important;
    -webkit-transition: background-color .5s, color .5s!important;
}

.target-selection-tab  .data-list .data-list-item label {
    white-space: nowrap;
    line-height: normal;
}

.target-selection-tab  .data-list .data-list-item:hover {
    border-style: solid;
    border-width: 0.1vw;
}
