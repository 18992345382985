.notification-item-container{
    width: 100%;
    min-height: 6vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    padding: 1vh 1vw;
    border: 1px solid var(--tableHeaderColor);
    cursor: pointer;
}

.notification-item-container:hover{
    background-color: var(--tableRowHover);
}
.notification-item-container .notifications-ico-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2vw;
    height: 2vw;
    background-color: var(--backgroundColor);
    border-radius: 50%;
    padding: 1vh .5vw;
    border: none;
}
.notification-item-container .notifications-ico{
    display: flex;
    width: 100%;
    height: fit-content;
    filter: invert(var(--filterRatio));
    -webkit-filter: invert(var(--filterRatio));  
}
.notification-item-container .desc{
    font-size: .8vw;
    color: var(--tableHeaderColor);
    width: 100%;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    cursor: pointer;
}