.forgot-password-parent {
    width: 100%;
    height: 100vh;
    background-color: var(--backgroundColor);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .forgot-password-parent .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
  }

  .forgot-password-parent .formbold-form-wrapper {
    height: auto;
    margin: 0 auto;
    width: 50%;
    box-sizing: border-box;
    padding: 2vw;
    padding: 2vh 1.5vw 2vh 1.5vw;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    box-shadow: 3px 3px 2px var(--boxShadowColor);

    overflow: auto;
  }
  .forgot-password-parent .formbold-form-title h2 {
    font-weight: 600;
    font-size: 1.2vw;
    line-height: 1vh;
    color: #feb64c;
  }
  .forgot-password-parent .formbold-form-title p {
    font-size: 0.7vw;
    color: var(--textColor);
    margin-top: 2vh;
  }
  .forgot-password-parent .formbold-input-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1vh;
    width: 100%;
    margin-bottom: 1vh;
  }
  .forgot-password-parent .formbold-input-flex > div {
    width: 100%;
  }
  .forgot-password-parent .formbold-form-input {
    text-align: center;
    width: 100%;
    padding: 1vh 1vw 1vh 1vw;
    border-radius: 5px;
    border: 1px solid #e9eeff;
    background: var(--backgroundColor);
    font-weight: 500;
    font-size: 0.7vw;
    color: var(--textColor);
    outline: none;
    resize: none;
  }
  .forgot-password-parent .formbold-form-input:focus {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }
  .forgot-password-parent .formbold-form-label {
    color: var(--textColor);
    font-size: 0.7vw;
    line-height: 24px;
    display: block;
    margin-bottom: 10px;
  }
  .forgot-password-parent .formbold-btn {
    font-size: 0.8vw;
    border-radius: 5px;
    padding: 1vh 1vw 1vh 1vw;
    border: none;
    font-weight: normal;
    background-color: #feb64c;
    color: white;
    cursor: pointer;
    width: 100%;
  }
  .forgot-password-parent .formbold-btn:hover {
    opacity: .5;
  }

  .forgot-password-parent  .formbold-form-wrapper .errorLabel {
    overflow-wrap: anywhere;
    color: red;
    font-size: 0.7vw;
  }
  .forgot-password-parent input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .forgot-password-parent input[type="number"] {
    -moz-appearance: textfield;
  }
  .forgot-password-parent .react-code-input{
    width: 100%;
    display: flex!important;
    flex-direction: row;
    justify-content: center;
  }

  .forgot-password-parent .count-down-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8vh;
  }