.edit-sla-file-container{
    display: flex;
    flex-direction: column;
    padding: 1vw 1vw;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    gap: 1vh;
}
.edit-sla-file-container .file-list-table-parent{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.edit-sla-file-container table{
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.edit-sla-file-container .table-header{
    width: 100%;
    
    text-align: center;
    color: var(--tableHeaderColor);
    font-weight: 400;
    font-size: .8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-sla-file-container .add-service-button{
    width: 5vw;
    height: 3vh;
    border-radius: 10px;
    border: none;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    font-size: .7vw;
    margin-left: auto;
}
.edit-sla-file-container table{
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.edit-sla-file-container thead{
    display: flex;
    width: 100%;
}
.edit-sla-file-container tbody{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1vh;
    overflow-y: auto;
}
.edit-sla-file-container tr{
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    padding-left: 1vw;
    padding-right: 1vw;
    min-height: 4vh;
}
.edit-sla-file-container tbody tr{
    background-color: var(--backgroundColor);
}
.edit-sla-file-container tbody tr:hover{
    background-color: var(--tableRowHover);
}
.edit-sla-file-container th{
    display: flex;
    color: var(--textColor);
    width: 80%;
    font-size: .8vw;
    font-weight: 200;
}
.edit-sla-file-container th:last-child{
    width: 20%;
}
.edit-sla-file-container .file-list-table-parent th{
    width: 80%
}
.edit-sla-file-container .file-list-table-parent th:last-child{
    width: 20%
}
.edit-sla-file-container td{
    display: flex;
    color: var(--textColor);
    width: 80%;
    font-size: .7vw;
    overflow: hidden;
    height: 100%;
    align-items: center;
}
.edit-sla-file-container td:last-child{
    width: 20%;
}
.edit-sla-file-container .upload-document-parent{
    margin-top: auto;
    display: flex;
    flex-direction: column;
}
.edit-sla-file-container .upload-btn {
    font-size: 0.7vw;
    border-radius: 5px;
    border: none;
    font-weight: normal;
    background-color: #feb64c;
    color: var(--textColor);
    width: 100%;
    margin-left: auto;
    height: 3vh;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
.edit-sla-file-container .loader {
  border: 0.15vw solid #f3f3f3; /* Light grey */
  border-top: 0.15vw solid #3498db; /* Blue */
  border-radius: 50%;
  width: 1vw;
  height: 1vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  
  