.email-sender-parent{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    background-color: rgb(0, 0, 0, .2);
}

.email-sender-parent .email-sender-container{
    position: absolute;
    left: 35vw;
    top: 35vh;
    width: 40vw;
    height: 40vh;
    background-color: var(--backgroundColor);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    animation: slideRight .5s ease-in-out;
    z-index: 999;
    overflow: hidden;
}

@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}
.email-sender-parent .ico-part{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
}

.email-sender-parent .ico-part .ico-part-img{
    display: flex;
    width: fit-content;
    height: 100%;
}
.email-sender-parent .select-users-part{
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 55%;
    width: 100%;
    background-color: var(--backgroundColor2);
    padding-top: 3vh;
    overflow-y: auto;
}
.email-sender-parent .email-sender-container .multiSelectContainer input{
    width: 100%;
    color: var(--textColor);
}
.email-sender-parent .email-sender-container .multiSelectContainer .chip{
    font-size: .8vw;
    background: var(--backgroundColor);
    color: var(--textColor);
}
#multiselectContainerReact > div.optionListContainer > .optionContainer > .notFound{
    font-size: .8vw;
}
#multiselectContainerReact > div.search-wrapper.searchWrapper > span > img{
    filter: invert(100%);
    -webkit-filter: invert(100%)
}
#multiselectContainerReact > div.optionListContainer > ul > li{
    font-size: .8vw;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    display: flex;

}
#multiselectContainerReact > div.optionListContainer > ul > li:hover{
    background-color: var(--backgroundColor);
}
.email-sender-parent .select-users-part > div{
    width: 90%;
}
.email-sender-parent .select-users-part .pop-up-text{
    width: 100%;
    height: 100%;
    color: var(--textColor);
    font-size: .8vw;
    font-weight: 400;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

}
.email-sender-parent .button-part{
    display: flex;
    flex-direction: row;
    height: 25%;
    width: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 0px 0px 9px 9px;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}
.email-sender-parent .button-part button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 40%;
    height: 50%;
    border-radius: 5px;
    border: none;
    background-color: var(--confirmColor);
    color: var(--textColor2);
    font-size: .8vw;
    overflow: hidden;
    margin-left: auto;
}
.email-sender-parent .button-part button:first-child{
    background-color: var(--cancelColor);
    margin-right: auto;
    margin-left: 0;
}