.card-parent{
    width: 22vw;
    height: 10vh;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    position: relative;
}

.card-parent .card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 10vh;
    padding: 1vh 3vw;
}
.card-parent .profile-photo-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 2px ;
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    border-color: #D0D6E2;
}
.profile-photo-parent:hover{
    opacity: 0.5;
}
.card-parent .text-area{
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    justify-content: center;
    color: var(--textColor);
    font-size: .7vw;
    color: #285cb0;
    font-weight: 400;
    font-size: .8vw;
}
.card-parent .text-area .icon{
    width: 7%;
}
.card-parent .online-color{
    margin-left: auto;
    font-size: 0.7vw;
    color: #79C47B;
}
.card-parent .offline-color{
    margin-left: auto;
    font-size: 0.7vw;
    color: #C53333;
}
.card-parent .arrow-area{
    height: 2vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
}
.card-parent .arrow-ico-userbar {
    height: 1.5vh;
    width: 0.75vw;
    filter: invert(50%);
}
.card-parent .arrow-ico-userbar:hover{
    opacity: 0.5;
}
.card-parent .arrow-area button{
    border: none;
    background-color: transparent;
}

.card-parent-side{
    width: 22vw;
    height: 20vh;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.card-parent-side .card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20vh;
    width: 100%;
    padding: 1vh 1vw;
}
.card-parent-side .profile-photo-parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    border-color: #D0D6E2;
    
}
.card-parent .profile-photo-parent img{
    width: 100%;
    height:100%; 
    border-radius: 50%;
}
.card-parent-side .profile-photo-parent img{
    width: 100%;
    height:100%; 
    border-radius: 50%;
}
.card-parent-side .text-area{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .7vw;
    width: 100%;
    height: 3vh;
    color: var(--textColor);
    word-break: break-word; 
    gap: 1vw;
}
.card-parent-side .online-color{
    margin-top: 1vh;
    color: #79C47B;
    font-size: 0.7vw;
}
.card-parent-side .offline-color{
    margin-top: 1vh;
    color: #C53333;
    font-size: 0.7vw;
}
.card-parent-side .arrow-area{
    width: 100%;
    height: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    position: absolute;
    bottom: 0
}
.card-parent-side .arrow-ico-userbar {
    height: 1.5vh;
    width: 0.75vw;
    filter: invert(50%);
}
.card-parent-side .arrow-ico-userbar:hover{
    opacity: 0.5;
}
.card-parent-side .arrow-area button{
    border: none;
    background-color: transparent;
}
.card-parent-side .card-container .text-area:nth-child(1) label{
    color: #285cb0;
    font-weight: 400;
    font-size: .8vw;
}
.card-parent-side .card-container .left-part{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin-right: 10%;
    margin-top: 2vh;
}
.card-parent-side .card-container .right-part{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 63%;
    
}
.card-parent-side .card-container .right-part .text-area .icon{
    width: 7%;
}
.card-parent-side .card-container .right-part:last-child{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 7%;
    margin-top: 1vh;
}
.card-parent-side .card-container .right-part button .icon{
    width: 100%;
}
.card-parent-side .card-container .right-part button {
    background-color: transparent;
    border: none;
    width: 75%;
}
.card-parent-side .card-container .right-part button:hover {
    opacity: 0.5;
}