*{
    margin: 0;
    padding: 0;
  
  }
.edit-target-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.edit-target-container .auto-selection-tab {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.edit-target-container .auto-selection-tab input[type="radio"] {
    margin-right: 0.5vw;
}

.edit-target-container .auto-selection-tab label {
    margin-right: 2vw;
}
.edit-target-container .auto-selection-tab .selected {
    font-weight: 900;
}

.select-targets-tab {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    width: 95%;
    height: 65%;
    padding: 1.5vh 0vw 0vh 0vw;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--backgroundColor2);
    overflow-y:auto;
    color: var(--textColor);
    gap: 6% 2%; 
    justify-content: flex-start;
    align-items: center;
}
.select-targets-tab::-webkit-scrollbar {
    display: none !important;
}

.select-targets-tab .triangle-up {
    width: 0;
    height: 0;
    border-left: 1vh solid transparent;
    border-right: 1vh solid transparent;
    border-bottom: 0.75vw solid #57bd84;
    margin: 0 auto;
    transition: border .5s;
    -webkit-transition:border .5s;
}

.select-targets-tab .triangle-down {
    width: 0;
    height: 0;
    border-left: 1vh solid transparent;
    border-right: 1vh solid transparent;
    border-top: 0.75vw solid #bd5757;
    transition: border .5s;
    -webkit-transition:border .5s;
} 

.select-targets-tab .square {
    height: 0.75vh;
    width: 1vw;
    background-color: #555;
    transition: border .5s;
    -webkit-transition:border .5s;
}

.month-status {
    background-color: var(--backgroundColor);
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 0.8vw;
    display: flex;
    flex-direction: row;
    min-height: 30%;
    min-width: 15%;
    width: auto;
    transition: all 0s!important;
    -webkit-transition: all 0s!important;
    -webkit-transition: all 0s!important;
    transition: background-color .5s, color .5s!important;
    -webkit-transition: background-color .5s, color .5s!important;
    padding: .5vh 0;
}

.month-status:hover {
    border-style: solid;
    border-width: 0.1vw;
}

.month-status-selected {
    border-style: solid;
    border-width: 0.1vw;
}
.month-status .target-desc-container{
    display: flex;  
    flex-direction: column;
    width: 80%;
    height: 100%;
}
.target-desc-container label{
    height: 33.3%;
}
.target-ico-kpiedit{
    height: 2vh;
    width: 1vw;
    filter: invert(50%);
    margin-left: 1vw;
}

.selectionTab{
    display: flex;
    flex-direction: row;
    height: 20%;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.selectionTab .target-mechanism-selection{
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 100%;
    align-items: center;
}

.target-mechanism-selection .target-weight{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 30%;
    align-items: center;
    justify-content: space-around;
}
.target-mechanism-selection .target-type{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 35%;
    justify-content: space-around;
    align-items: center;
}
.target-mechanism-selection .target-range{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 35%;
    justify-content: space-around;
    align-items: center;
}

.selectionTab .target-value-input-container{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.selectionTab input[type="radio"]{
    margin-right: .5vw;
}

.selectionTab input[type="range"]{
    width: 70%;
    height: 1vh;
}
.selectionTab .target-value-input-container input[type="number"]{
    width: 70%;
    border-radius: 5px;
    background-color: var(--backgroundColor)!important;
    border: none;
    text-align: center;
    font-size: .8vw;
}
.selectionTab .target-weight input[type="number"]{
    width: 50%;
    border-radius: 5px;
    background-color: var(--backgroundColor)!important;
    border: none;
    text-align: center;
    font-size: .8vw;
}

.selectionTab .vertical-line {
    width: 1px;
    height: 40%;
    background-color: black; 
}
#percentageRange {
    /* Mevcut stil ayarları */
    appearance: none;
    height: 10px;
    border-radius: 5px;
    background: white; /* Beyaz arka plan rengi */
    outline: none;
    opacity: .5;
    transition: opacity 0.2s;
  }
  
  