*{
  margin: 0;
  padding: 0;
}
.kpi-edit-parent{
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: var(--frameWidth) ;
    height: 93vh;
    background-color: var(--backgroundColor);
    z-index: 50;
    top: 0vh;
    left: -100%;
}

.kpi-edit-parent-side-open{
  left:  0%;
}

.kpi-edit-parent-side-close{
  left: -100%;
}


.kpi-edit-parent label{
    display: flex;
    color: var(--textColor);
    font-size: 0.7vw;
    font-weight: normal;
    margin: 0;
    width: auto;
}

.kpi-edit-parent select{
    font-size: 0.7vw;
    border-radius: 5px;
    margin-left: 0.5rem;
    background-color: var(--backgroundColor);
    border: none;
    padding: 0.25vh 0.25vw 0.25vh 0.25vw;
}

.kpi-edit-parent label label{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--textColor);
  font-size: 0.7vw;
  font-weight: bold;
  margin: 1vh 0vw 1vh 2vw;
}


.kpi-edit-parent .border{
  border-style: solid;
  border-width: .2vw;
  border-color: red;
  display: flex;
  position: absolute;
  width: 100%;
  height: 15vh;
  background: transparent;
  flex-direction: column;
  align-items: flex-end;
}
.kpi-edit-parent .nextStepButton{
  position: absolute;
  text-align: center;
  right: 0;
  padding: .5vh 1vw;
  color: var(--textColor);
  font-size: .7vw;
  width: 5vw;
  margin-top: auto;
  z-index: 5;
}
.kpi-edit-parent .tip-label{
  line-height: 3vh;
  position: absolute;
  right: 0;
  padding: .5vh 1vw;
  background-color: #efefef;
  color: var(--textColor);
  border-style: solid;
  border-width: .1px;  
  font-size: .7vw;
  z-index: 5;
}
.kpi-edit-parent .navbar-root {
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 3vw;
  margin-right: 3vw;
  display: flex;
  flex-direction: row;
  height: 5vh;
}
.kpi-edit-parent .navbar-root .info-box {
  display: flex;
  flex-flow: row wrap;
}
.kpi-edit-parent  .navbar-root .info-box .page-logo{
  width: 2.5vw;
  height: 5vh;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundColor3)!important;
  box-sizing: border-box;
  padding: 1vh .5vw;
} 
.kpi-edit-parent .navbar-root .info-box .page-logo img{
  width: 100%;
  height: 100%;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
.kpi-edit-parent .navbar-root .info-box .page-info{
  margin-left: 1vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.kpi-edit-parent .navbar-root .info-box .page-info label{
  color: var(--textColor);
  font-size: .8vw;
  font-weight: 500;
}
.kpi-edit-parent .navbar-root .info-box .page-info label:nth-child(2){
  font-size: .6vw;
}
.kpi-edit-parent .chart-part{
  height: 35vh;
  box-sizing: border-box;
  margin: 0 3vw;
}
.kpi-edit-parent .edit-kpi-engine-container{
  width: auto;
  height: 45vh;
  margin: 4vh 3vw 0 3vw;
  display: flex;
}