.export-btn {
    font-size: 0.7vw;
    border-radius: 5px;
    border: none;
    font-weight: normal;
    background-color: #feb64c;
    color: var(--textColor);
    width: 100%;
    margin-top: 1vh;
    margin-left: auto;
    height: 4vh;
  }
  .export-btn:hover {
    opacity: .5;
  }