.parent-container-customer-detail {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container-customer-detail {
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor2);
  border-radius: 5px;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;

}

.container-customer-detail tbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 1vw;
  padding-left: 1vw;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: #eee;
}

.container-customer-detail tbody tr {
  display: flex;
  flex-direction: row;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  position: relative;
  min-height: 4vh;
  width: 100%;
  margin-top: 1vh;
  text-align: center;
  border-radius: 5px;
}
.container-customer-detail tbody tr:first-child {
  margin-top: 0;
}
.container-customer-detail tbody tr td {
  font-size: 0.7vw;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding-left: 1%;
}

.container-customer-detail thead {
  background-color: var(--backgroundColor2);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1vw;
  padding-right: 1VW;
  color: var(--textColor);
  border-radius: 10px;
  min-height: 6vh;
  height: 6vh;
  align-items: center;
  justify-content: center;
}

.container-customer-detail thead tr {
  display: flex;
  width: 100%;
  border-radius: 5px;
  background-color: var(--backgroundColor2);
  color: var(--textColor);
}



.container-customer-detail thead tr th {
  font-weight: 400;
  font-size: 0.8vw;
  color: var(--tableHeaderColor);
  padding-left: 1%;
}

.container-customer-detail td:nth-child(1),
.container-customer-detail th:nth-child(1) {
  width: 30%;
}

.container-customer-detail td:nth-child(2),
.container-customer-detail th:nth-child(2) {
  width: 20%;
}
.container-customer-detail td:nth-child(3),
.container-customer-detail th:nth-child(3) {
  width: 30%;
}

.container-customer-detail td:nth-child(4),
.container-customer-detail th:nth-child(4) {
  display: flex;
  flex-direction: row-reverse;
  width: 20%;
}

.container-customer-detail tbody>tr:hover {
  background-color: var(--tableRowHover);
}



@media (max-width: 800px) {

  .container-customer-detail td:nth-child(4),
  .container-customer-detail th:nth-child(4) {
    display: none;
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}

.square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}

.customer-name {
  width: max-content;
  box-sizing: border-box;
  font-size: 1vw;
  text-align: left;
  font-weight: 800;
  margin: 1%;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: auto;
}

.customer-name-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.parent-container-customer-detail .nav-root .info-box{
  margin-right: 0;
}

.customer-name-label {
  font-size: .8vw;
  font-weight: 500;
  color: var(--textColor);
}

.customer-detail-chart {
  width: 100%;
  height: 10%;
  overflow-x: scroll;
  display: flex;
  border-radius: 5px;
  background-color: var(--backgroundColor2);
  margin-bottom: 1vh;
}

.customer-detail-chart-side {
  height: 0%;
}

.yearly-trend-parent-box-customer-detail {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding: 1% 2%;
  height: 100%;
  gap: 1vw;
}

.yearly-trend-parent-box-customer-detail .trend-container {
  display: flex;
  flex-direction: column;
  color: #000;
  justify-content: center;
  text-align: center;
  font-size: 0.7vw;
  font-weight: bold;
}

.yearly-trend-parent-box-customer-detail .month {
  text-align: center;
  background-color: var(--backgroundColor2);
  color: var(--textColor);
  ;
  display: flex;
  flex-flow: column wrap;
  width: 5vw;
  height: 3vh;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.yearly-trend-parent-box-customer-detail .month:hover {
  background-color: var(--tableRowHover);
  color: var(--textColor);
  font-weight: normal;
  font-size: medium;
  border-radius: 5px;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: all;
  transition-timing-function: line;
}

.yearly-trend-parent-box-customer-detail .month-triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}

.yearly-trend-parent-box-customer-detail .month-triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}

.yearly-trend-parent-box-customer-detail .month-square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}

.parent-container-customer-detail .trend-button {
  width: 1.2vw;
  height: fit-content;
  margin-right: 1vw;
}

.parent-container-customer-detail .trend-button:hover {
  opacity: 0.5;
}

.chart-component-sla-kpi {
  width: 100%;
  height: 100%;
  margin-bottom: 2vh;
  background-color: var(--backgroundColor2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  animation: slideUp .5s ease-in-out;
}


@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.chart-component-sla-kpi-container {
  width: 90%;
  height: 90%;
}

.parent-container-customer-detail .add-sla-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 0vh;
  background-color: var(--backgroundColor);
  z-index: 1;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
  box-sizing: border-box;
  overflow-x: auto;
}
.parent-container-customer-detail .copy-sla-button-container{
  margin-left: auto;
  display: flex;
  flex-direction: row;
  padding: 0 1vw;
}

.parent-container-customer-detail .add-sla-parent-close {
  display: flex;
  flex-direction: row;  
  align-items: center;
  width: 20vw;
  height: 0vh;
  background-color: var(--backgroundColor);
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}
.parent-container-customer-detail .add-sla-parent-content {
  display: flex;
  align-items: center;
  margin-left: .5vw;
  flex-direction: row;
  min-width: 50%;
  gap: .5vw;
  overflow-x: auto;
  overflow-y: auto;
}

.parent-container-customer-detail .add-sla-parent-content::-webkit-scrollbar {
  height: 2px!important;
}

.parent-container-customer-detail .add-sla-parent-side .add-sla-parent-content {
  width: 57%;
}

.parent-container-customer-detail .companyNameTitle-italic {
  font-style: italic;
  font-size: .7vw;
  margin-right: .5vw;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.parent-container-customer-detail .companyNameTitle-bold {
  font-weight: 500;
  font-size: .7vw;
  margin-right: .5vw;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .parent-container-customer-detail .add-sla-parent-close {
  position: absolute;
  width: 20vw;
  height: 0vh;
  background-color: var(--backgroundColor);
  z-index: 9;
  right: 0;
  bottom: -50vh;
  border-radius: 5px;
  overflow: hidden;
} */

.parent-container-customer-detail .company-table {
  width: 100%;
  height: 90%;
}

.parent-container-customer-detail .company-table thead {
  background-color: var(--backgroundColor);
}

.parent-container-customer-detail .company-table thead tr {
  width: 100%;
  background-color: var(--backgroundColor);
  justify-content: center;
  display: flex;
}

.parent-container-customer-detail .company-table tbody {
  height: 100%;
  overflow-y: auto;
}

.parent-container-customer-detail .company-table tbody tr {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--backgroundColor2);
  padding: .5vh 0;

}

.parent-container-customer-detail .company-table tbody tr td {
  word-break: break-all;
  display: flex;
}

.parent-container-customer-detail .company-table tbody tr td:nth-child(1) {
  display: flex;
  align-items: center;
  width: 20%;
}

.parent-container-customer-detail .company-table tbody tr td:nth-child(2) {
  width: 80%;
}

.parent-container-customer-detail .company-table thead tr th:nth-child(1) {
  display: flex;
  align-items: center;
  width: 20%;
}

.parent-container-customer-detail .company-table thead tr th:nth-child(2) {
  width: 80%;
}

.parent-container-customer-detail .company-table input[type="checkbox"] {
  height: 1.75vh;
}

.copy-sla{
  width: 1vw;
  height: fit-content;
  display: flex;
  margin-right: 1vw;
}
.copy-sla:hover{
  opacity: .5;
}

.up-arrow{
  width: 100%;
  height: fit-content;
  display: flex;
  content: url("../assets/up-arrow.png");
}
.up-arrow:hover{
  opacity: .5;
}

.up-arrow-button {
  border: none;
  background-color: transparent;
  width: 1vw;
  padding: 0;
  margin: 0;
}



.copy-sla-button {
  width: auto;
  padding: 0;
  margin: 0;
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
  border: .8px solid #000;
  padding: .8vh 2.2vw;
  border-radius: 10px;
  background-color: var(--backgroundColor2);
  color: var(--textColor);
  transition:all .2s!important;
  font-weight: 200;
  font-size: .7vw;
}
.copy-sla-button:hover{
  background-color: var(--goldColor);
  
}