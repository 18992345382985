.basicKpiInfo-parent{
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 1vw;
    margin-left: auto;
}
.basicKpiInfo-parent .edit-form-part{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;    
    height: 100%;
    box-sizing: border-box;
    gap: 1vw;
}
.basicKpiInfo-parent .edit-form-part input[type="month"]{
  background-color: var(--backgroundColor2);
  border-radius: 10px;
}
.basicKpiInfo-parent .edit-form-part label{
  display: flex;
  color: var(--textColor);
  font-size: 0.7vw;
  font-weight: normal;
  margin: 0;
  line-height: 100%;
  white-space: nowrap;
}

.basicKpiInfo-parent .edit-form-part select{
    font-size: 0.7vw;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor2);
    text-align: start;
    border: none;
    color: var(--textColor);
    height: 4vh;
    border-radius: 10px;
    margin: 0;
    min-width: 10vw;
}
.basicKpiInfo-parent .edit-form-part input[type="text"]{
  font-size: 0.7vw;
  display: flex;
  background-color: var(--backgroundColor2);
  text-align: start;
  border: none;
  color: var(--textColor);
  height: 4vh;
  border-radius: 10px;
  width: fit-content;
  min-width: 10vw;
  padding: 0 .5vw ;
}