
.counter-box-three-part-container{
    width: 20%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    box-sizing: border-box;
    padding:  1vh 1.5vw;
}
.counter-box-three-part-container .top-part{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    box-sizing: border-box;
    height: 50%;
    gap: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding: 0 1vw;
    width: 100%;
}
.counter-box-three-part-container .bottom-part{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1vw;
    justify-content: space-between;
    padding-bottom: 1vh;
    padding-top: 1vh;
    gap: .8vh;
}
.counter-box-three-part-container .bottom-part .legend-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1vw;
}
.counter-box-three-part-container .bottom-part .legend-container .legend{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5vw;
    color: var(--textColor);
    font-size: .7vw;
    height: 1.5vh;
}
.counter-box-three-part-container .bottom-part .circle{
    width: .7vw;
    height: .7vw;
    border-radius: 50px;
    display: flex;
    
}
.counter-box-three-part-container #counter-ico{
    width: 2vw;
    height: fit-content;
    -webkit-filter: invert(var(--filterRatio));
    filter: invert(var(--filterRatio));
}
.counter-box-three-part-container .top-part .count-number{
    font-weight: 200;
    font-size: 1.2vw;
    color: var(--tableHeaderColor);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 1;
}
.counter-box-three-part-container .top-part .count-text{
    font-weight: 400;
    font-size: .9vw;
    color: var(--tableHeaderColor);
    line-height: 1;
}


.counter-box-three-part-container .ico-text-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height:100%;
    width: 100%;
}
.counter-box-three-part-container .top-part .count-info-container{
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

}

hr{
    border-color: #000;
}
@media (max-width: 1200px) {

}
@media (max-width: 900px) {

}
@media (max-width: 600px) {    
}
@media (max-width: 300px) {

}
