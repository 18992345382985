
.main-page-style{
    background: var(--backgroundColor);
    width: 96vw;    
    height: 100vh;
    display:flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
}
.main-page-style-side{
  width: 84vw;

}
.main-page-style-2{ 
  background: var(--sidebarBackgroundColor);
  width: 16%;    
  height: 100vh;
  display: flex;
  flex-flow: column;
  z-index: 100;
  left: 0vh;
  position: relative;
  overflow: hidden;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}
.main-page-style-2-side{

  width: 0vw;    


}
.logo-main{
  position: relative;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  height: 7vh;
}
.experilabs-ico-wide{
  width: 12vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin:auto;
  margin-left: 2vw;
}
.experilabs-ico-small{
  width: 2vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin:auto;
}


.new-sla-button{
    font-size: 0.7vw;
    line-height: 5vh;
    max-height: 5vh;
    width: 12vw;
    margin-left: 1vw;
    margin-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    box-sizing: border-box;
    border-color: hsla(0,0%,100%,.2);
    border-width: 1px;
    border-radius: 5px;
    transition-duration: .2s;
    color: #fff;
    background-color: #fcb459;
    white-space: nowrap;
      overflow: hidden;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
}

.new-sla-button:hover{
  opacity: .5;
}
.new-sla-button-active{
  opacity: .5;
}


.main-page-style-2-close hr{
  border: 1px solid rgb(255, 255, 255)!important;
  margin: 0.5vh .5vw;
}

.button-main-1:active {
  box-shadow: none;
  transform: translateY(0);
}
.main-page-style-2 .button-main-1 {
  font-size: 0.7vw;
  line-height: 5vh;
  max-height: 5vh;
  width: 100%;
  box-sizing: border-box;
  color: #b8b8b8;
  background-color: transparent;
  border: none;
  text-align: left;
  padding-left: 1vw;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
.main-page-style-2-close .button-main-1,
.main-page-style-2-close-side .button-main-1{
  overflow: hidden; /* İçerideki içeriğin dışarı taşmasını engellemek için */
  width: 100%;
  background-color: transparent;
  border: none;
  height: 5vh;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  position: relative;
}

.button-main-1:disabled {
  pointer-events: none;
}

.button-main-1:hover {
  background-color: var(--sidebarHoverBackgroundColor);
}
.button-main-1-active{
  background-color: var(--sidebarHoverBackgroundColor)!important;
}
.button-main-1:focus {
  background-color: var(--sidebarHoverBackgroundColor);
}

.h7-style{
  font-size: 0.9vw;
  padding-left: 1vw;
  color: #b8b8b8;
  margin-bottom: .8vh;
  margin-top: 1vh;  
  max-height: 5vh;
  white-space: nowrap;
  overflow: hidden;
}
.main-page-style-2-close{
  background: var(--sidebarBackgroundColor);
  width: 4vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  z-index: 100;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  overflow: hidden;

}
.main-page-style-2-close-side{
  display: flex;
  flex-flow: column;
  background: var(--sidebarBackgroundColor);
  width: 0vw;
  -webkit-transition: width 0.5s;
  height: 100vh;
  overflow: hidden;
  transition: width 0.5s;
}
.main-page-style-2-close .logo-main{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  height: 7vh;  
}
.main-page-style-2-close .new-sla-button{
  background: #263644;
  font-size: 0.7vw;
  width: 80%;
  margin-left: 0.5vw;
  margin-bottom: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  box-sizing: border-box;
  border-color: hsla(0,0%,100%,.2);
  border-width: 1px;
  border-radius: 5px;
  transition-duration: .2s;
  color: #fff;
  background-color: #fcb459;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  

}
.arrow-button-right{
  background-color: transparent;
  border: none;
  -webkit-transition: left 0.5s;
  transition: left 0.5s;
  margin-top: auto;
}
.arrow-button-left:hover{
  opacity: .5;
}
.arrow-button-left{
  margin-top: auto;
  background-color: transparent;
  border: none;
  -webkit-transition: left 0.5s;
  transition: left 0.5s;

}
.arrow-button-right:hover{
  opacity: .5;
}
.small-ico{
    width: 1.4vw;
    filter: invert(100%);
    -webkit-filter: invert(100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}