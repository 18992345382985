.parent-basket{
    width: 23.5vw;
    max-width: 23.5vw;
    height: 100%;
    overflow: hidden;
    box-shadow: 3px 3px 2px var(--boxShadowColor);
    border-radius: 10px;
    border-right-style: solid;
    border-width: 1.5vw;
    border-color: #feb64c;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--backgroundColor2);
    padding: 1vw;
}

.parent-basket .basket-box-parent{
    width: 21.5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.parent-basket .label-basket-1{
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--textColor);
    font-size: 0.8vw;
    font-weight: bold;
    padding-left: 7%;
         
}
.parent-basket input[type=text]{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor);
    text-align: center;
    border: none;
    color: var(--textColor);
    width: 20vw;
    height: min-content;
    border-radius: 5px;
    margin-bottom: 0;
    
    height: 4vh;
    font-size: 0.7vw;
}

.parent-basket .basket-box{
    overflow-y: scroll;
    scroll-snap-align: end;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 21vw;
}
.parent-basket .basket-box::-webkit-scrollbar {
    display: none;
}

.parent-basket .basket-box-box{
    background-color: #feb64c;
    border: 1px solid #202123;
    border-radius: 20px;
    color: var(--textColor);
    padding: .5vh 1vw;
    margin-top: 10px;
    text-align: center;
    box-sizing: content-box;
         
    font-size: 0.7vw;
}
.parent-basket .basket-box-box-right-arrow{
    display: flex;
    justify-content: center;
    align-items: center;
         
}
/* .parent-basket .basket-box-box-right-arrow:last-child{
    display: none;
} */
.parent-basket .button-delete{
    width: 50%;
    border: none;
    background-color: #d9534f;
    border-radius: 5px;
    justify-content: center;
    height: 3vh;
    color: white;
    margin-top: 1vh;
    margin-left: auto;
    font-size: 0.8vw;
         
}
.parent-basket .button-delete:hover{
    opacity: 0.5;
}
.parent-basket .button-edit:hover{
    opacity: 0.5;
}
.parent-basket .button-edit{
    width: 50%;
    border: none;
    background-color: #428bca;
    border-radius: 5px;
    height: 3vh;
    margin-top: 1vh;
    color: white;
    margin-right: auto;
    font-size: 0.8vw;
}


.parent-basket .button-confirm-basket{
    width: 100%;
    border: none;
    background-color: #5cb85c;
    border-radius: 5px;
    justify-content: center;
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--textColor);
    margin-right: .25vw;
    margin-left: auto;
         
    font-size: 0.8vw;
}
.parent-basket .button-confirm-basket:hover{
    opacity: 0.5;
}
.parent-basket .right-arrow-basket-icon{
    width: 1vw;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
         
}
.parent-basket-side{
    width: 0vw;
    padding: 1vw 0vw 1vw 0vw;
}
.alert-basket-side{
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 17vw;
    height: 40vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  .alert-basket{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 17vw;
    height: 40vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  .alert-basket .part-1, .alert-basket-side .part-1{
    height: 35%;
    width: 100%;
    border-radius: 10px;
    background-color: #b6750d;
    border-radius: 10px 10px 0px 0px ;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .alert-basket .part-2, .alert-basket-side .part-2{
    height: 65%;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    background-color: #f39c11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh 1vw;
    justify-content: space-around;
  }
  .alert-basket .part-2 label, .alert-basket-side .part-2 label{
    display: flex;
    text-align: center;
    color: #fff;
    font-weight: normal;
  }
  .alert-basket .part-2 .part-button, .alert-basket-side .part-2 .part-button{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .alert-basket .part-2 .part-button button, .alert-basket-side .part-2 .part-button button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30%;
    padding: 1vh 1vw;
    border-radius: 10px;
    border: none;
  }
  .alert-basket .part-2 .part-button button:hover{
    opacity: .5;
  }
  .parent-basket hr {
    margin: 1vh 0;
  }