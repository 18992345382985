* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.parent-new-service {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.parent-new-service .formbold-form-wrapper {
  height: 80vh;
  margin: 0 auto;
  width: 100%;
  padding: 2vh 1vw 2vh 1vw;
  background-color: var(--backgroundColor2);
  border-radius: 10px;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  color: var(--textColor);
  position: relative;
}

.parent-new-service .formbold-form-wrapper form {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.parent-new-service .formbold-form-title h2 {
  font-weight: 600;
  font-size: 1.2vw;
  color: #feb64c;
}

.parent-new-service .formbold-form-title p {
  font-size: .7vw;
  color: var(--textColor);
  margin-top: 2vh;
}

.parent-new-service .formbold-input-flex {
  gap: 1vw;
  display: flex;
  margin-bottom: 1vh;
}

.parent-new-service .formbold-input-flex>div {
  width: 100%;
}

.parent-new-service .formbold-desc-container {
  margin-top: 1vh;
  width: 100%;
  font-size: .6vw;
  color: var(--textColor);
  background-color: var(--backgroundColor);
  border-radius: 5px;
  padding: 1vh 1vw;
  margin-bottom: 1vh;
}

.parent-new-service .formbold-form-input {
  text-align: center;
  width: 100%;
  padding: 1vh 2vw;
  border-radius: 5px;
  border: 1px solid #e9eeff;
  background: var(--backgroundColor);
  font-weight: 500;
  font-size: 0.7vw;
  color: var(--textColor);
  outline: none;
  overflow: hidden;
  height: 4vh;
}

.parent-new-service .formbold-form-textarea{
  text-align: left;
  width: 100%;
  padding: 1vh 1vw;
  border-radius: 5px;
  border: 1px solid #e9eeff;
  background-color: var(--backgroundColor);
  font-weight: 500;
  font-size: .7vw;
  color: var(--textColor);
  outline: none;
}

.parent-new-service .formbold-form-input:focus {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.parent-new-service .formbold-form-label {
  color: var(--textColor);
  font-size: .7vw;
  display: block;
}

.parent-new-service .formbold-btn {
  border-radius: 5px;
  border: none;
  font-weight: normal;
  display: flex;
  background-color: #feb64c;
  color: white;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  font-size: 0.8vw;
  height: 4vh;
  min-height: 4vh;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.parent-new-service .formbold-btn:hover {
  opacity: .5;
}

.parent-new-service .errorLabel {
  overflow-wrap: anywhere;
  color: red;
  font-size: 0.7vw;
}

.parent-new-service .formbold-form-wrapper .to-new-service-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 4vh;
  width: 3vw;
  background-color: var(--backgroundColor3) !important;
  color: #ffffff;
  display: flex;
  margin-left: auto;
  border: none;
  border-radius: 0px 10px 0px 10px;
  justify-content: center;
  align-items: center;
}
.parent-new-service .formbold-form-wrapper form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.parent-new-service .formbold-form-wrapper .to-new-service-button .close-button-ico {
  width: 1vw;
}