.company-detail-parent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    animation: slideRight .5s ease-in-out;
  }

  @keyframes slideRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}
  
.company-detail-parent .container-company-detail{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 82%;
    border-collapse: collapse;
    border-radius: 5px;
    overflow-x: auto;
    overflow-y: auto;
    background-color: var(--backgroundColor2);
    box-shadow: 3px 3px 2px var(--boxShadowColor);
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 2%;
}

.company-detail-parent .container-company-detail thead{
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 5px;
    width: 100%;
}

.company-detail-parent .container-company-detail thead th{
    text-align: center;
    background-color: var(--backgroundColor2);
    height: 4vh;
    font-size: .8vw;
    font-weight: 400;
    color: var(--tableHeaderColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-detail-parent .container-company-detail tbody{
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 5px;
}

.company-detail-parent tr{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3vh;
    height: auto;
}

.company-detail-parent .container-company-detail tbody tr{
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-bottom: 1vh;
    border-radius: 5px;
    background-color: var(--backgroundColor);
    min-height: 4vh;
}

.company-detail-parent td,
.company-detail-parent th{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--textColor);
    min-width: 10vw;
    max-width: 15vw;
    height: auto;
    word-wrap: break-word;
    font-size: .7vw;
    vertical-align: middle;
}

.company-detail-parent .container-company-detail tbody tr:hover{
    background-color: var(--tableRowHover);
    transition-delay: 0s;
    transition-duration: .5s;
    transition-property: all;
    transition-timing-function: line;
}



