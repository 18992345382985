.parent-new-company {
    width: 100%;
    height: auto;
    background-color: var(--backgroundColor);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
.new-company-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93vh;
}
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .parent-new-company .formbold-stroke-current {
    stroke: currentColor;
  }
  #supportCheckbox:checked ~ div span {
    opacity: 1;
  }
  .parent-new-company .formbold-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }

  .parent-new-company .formbold-form-wrapper {
    height: 80vh;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 1.5vw 2vh;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    box-shadow: 3px 3px 2px var(--boxShadowColor);
    overflow: auto;
  }
  .parent-new-company .formbold-form-wrapper form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .parent-new-company .formbold-form-title h2 {
    font-weight: 600;
    font-size: 1.2vw;
    line-height: 1vh;
    color: #feb64c;
  }
  .parent-new-company .formbold-form-title p {
    font-size: 0.7vw;
    color: var(--textColor);
    margin-top: 2vh;
  }
  .parent-new-company .formbold-input-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1vw;
    width: 100%;
    margin-bottom: 1vh;
  }
  .parent-new-company .formbold-input-flex > div {
    width: 100%;
  }
  .parent-new-company .formbold-form-input {
    text-align: center;
    width: 100%;
    padding: 1vh 1vw 1vh 1vw;
    border-radius: 5px;
    border: 1px solid #e9eeff;
    background: var(--backgroundColor);
    font-weight: 500;
    font-size: 0.7vw;
    color: var(--textColor);
    outline: none;
    resize: none;
  }
  .parent-new-company .formbold-form-input:focus {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }
  .parent-new-company .formbold-form-label {
    color: var(--textColor);
    font-size: 0.7vw;
    display: block;
  }
  .parent-new-company .formbold-btn {
    font-size: 0.8vw;
    border-radius: 5px;
    padding: 1vh 1vw 1vh 1vw;
    border: none;
    font-weight: normal;
    background-color: #feb64c;
    color: white;
    cursor: pointer;
    width: 100%;
    margin-top: auto;
    height: 4vh;
  }
  .parent-new-company .formbold-btn:hover {
    opacity: .5;
  }
  .parent-new-company .formbold-main-wrapper .formbold-form-wrapper .errorLabel {
    overflow-wrap: anywhere;
    color: red;
    font-size: 0.7vw;
  }
  .new-company-container .selected-comp-label{
    font-size: .8vw;
    color: var(--textColor);
    font-weight: 200;
    margin-right: 1vw;
  }
  .new-company-container .newCompanyGroup{
    background-color: var(--backgroundColor2);
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0vh 2.2vw;
    height: 4vh;
    line-height: 1;
    font-size: .7vw;
    font-weight: 200;
  }
  .new-company-container .newCompanyGroup:hover{
    background-color: var(--goldColor);
    opacity: 1;
  }
  .new-company-container .newCompanyGroup img {
    margin-right: 1vw;
    width: 1vw;
    height: fit-content;
  }
  .new-company-container select{
    width: 10vw;
    height: 4vh;
    border-radius: 10px;
    margin-right: 1vw;
    background-color: var(--backgroundColor2);
    color: var(--textColor); 
  }
  .new-company-container .new-company-group-input{
    width: 20%;
    height: 4vh;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
  }
  .new-company-container .cancelCompanyGroup{
    width: 1.5vw;
    height: fit-content;
    margin-left: 1vw;
    cursor: pointer;
  }
  .new-company-container .confirmCompanyGroup{
    width: 1.5vw;
    height: fit-content;
    margin-left: .5vw;
    cursor: pointer;
  }