.import-table-parent{
    width: 97%;
    height: 90%;
    padding: 1.5vw 1.5vw 0vw 1.5vw;
    background-color: var(--backgroundColor2);
    border-radius: 5px;
}
.import-table-container{
    width: 100%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
  .import-table-column-container{
    display: flex;
    flex-direction: column;
  }
  .import-table-row-container{
    display: flex;
    flex-direction: row;
    width: min-content;
  }
  .import-table-row-container:nth-child(odd){
    background-color: var(--importTableColor);
    
  }
  .import-table-cell{
    border: none;
    background-color: transparent;
    
}

.input-cell-table{
    text-align: center!important;
    width: auto!important;
    height: 100%!important;
    background-color: transparent;
    border: .1px solid var(--textColor)!important;
    border-width: 0.1px!important;
    font-size: 0.7vw!important;
    color: var(--textColor)!important;
    border-color: var(--textColor)!important;
    border-radius: 0px!important;
}
.import-table-cell:hover{

    opacity: 0.5;
}

.import-table-header-cell{
    border: none;
    background-color: transparent;
}

.header-label-cell-table{
    text-align: center!important;
    width: auto!important;
    height: 3vh!important;
    background-color: var(--importTableColor2)!important;
    border-width: 0.1px!important;
    font-size: 0.7vw!important;
    color: var(--textColor)!important;
    border-color: var(--textColor)!important;
    border-radius: 0px!important;
    border: .1px solid var(--textColor)!important;
}
.import-table-parent-submit-button-parent{
 height: 10%;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 pointer-events: all;
}
.import-table-parent-submit-button{
  height: 4vh;
  width: 10vw;
  display: flex;
  border-radius: 5px;
  background-color: #feb64c;
  border: none;
  font-size: 0.7vw;
  text-align: center;
  justify-content: center;
  align-items: center;
  pointer-events: all;

}
.import-table-parent-submit-button:hover{
  opacity: .5;
}