.settings-frame-parent{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.settings-frame-parent .active-settings-type-header{
    font-size: 1.4vw;
    font-weight: 400;
    color: var(--textColor);
    text-transform: uppercase;
}
.settings-frame-parent > hr{
    margin: 1vh 0 2vh 0;
    border-color: grey;
}
.settings-frame-parent .setting-container{
    width: 100%;
    padding: 1vh 0;
    display: flex;
    flex-direction: column;
    gap: .5vh;
}
.settings-frame-parent .setting-container .setting-header{
    font-size: 1vw;  
    color: var(--textColor);
    text-transform: capitalize;
    font-weight: 400;
}
.settings-frame-parent .setting-container .description{
    font-size: .8vw;  
    color: var(--textColor);
    
}
.settings-frame-parent .setting-container .range-value{
    color: var(--textColor);
    font-size: 1.5vw;
    text-align: center;
    font-weight: 600;
}
.settings-frame-parent input[type="range"]{
    height: 1vh;
}
.settings-frame-parent .setting-container .radio-label{
    font-size: .8vw;
    color: var(--textColor);
    text-transform: capitalize;
    gap: .5vw;
    display: flex
}
.settings-frame-parent .save-button{
    width: 7vw;
    height: 4vh;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    border: 1px solid var(--backgroundColor3);
    margin-top: 2vh;
}