.message-box-parent{
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
}
.message-box{
    display: flex;
    box-sizing: content-box;
    padding:0.5vh 1vw 0.5vh 1vw;
    margin: 1vh 1vw;
    color: var(--textColor);
    border-radius: 20px;
    background-color: var(--backgroundColor);
    min-width: 4vw;
    max-width: 15vw;
    width: Auto ;
    font-size: 0.7vw;
}
.message-box input{
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: .7vw;
    color: var(--textColor);

}
.message-box input:focus {
    outline: none;
  }
  .message-box-parent .exit{
    border-radius: 20px;
    border: none;
    height: 4vh;
    width: min-content;
    margin: 1vh 0vw;
    background-color: transparent;
    box-sizing: border-box;
    padding:0.5vh 0;
    font-size: 0.7vw;
}
.message-box-parent .exit img{
    width: 1vw;
}
.message-box-parent .exit:hover{
    scale: 1.5;
}