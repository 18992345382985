.new-sla-meta-data-parent{
    width: 22.5vw;
    max-width: 22.5vw;
    height: 100%;
    color: #fff;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    margin-left: 5%;
    box-shadow: 3px 3px 2px var(--boxShadowColor);
    border-left-style: solid;
    border-width: 1.5vw;
    border-color: #feb64c;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
.new-sla-meta-data-parent .searchWrapper{
  border: none;
  padding: 0;
}
.new-sla-meta-data-parent .option{
  background-color: var(--backgroundColor2);
  color: var(--textColor);
  font-size: .8vw;
}
.new-sla-meta-data-parent .option:hover{
  background-color: #f0f0f0;
  color: var(--textColor);
}
.new-sla-meta-data-parent .multiSelectContainer li{
  padding: .2vw;
}
.new-sla-meta-data-parent .chip{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-color: var(--backgroundColor);
  color: var(--textColor);
}
.new-sla-meta-data-parent .closeIcon{
  filter: invert(100%);
}
.new-sla-meta-data-parent .edit-button-container{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 1;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}
.new-sla-meta-data-parent .edit-button-container img {
  display: flex;
  width: 3vw;
  height: fit-content;
}
.new-sla-meta-data-parent .edit-button-container:hover{
  opacity: .1;
}


.new-sla-meta-data-parent .errorLabel{
  overflow-wrap: anywhere;
  color: red;
  font-size: 0.6vw;
  display: flex;
  align-items: left;
  justify-content: left;
  background-color: var(--backgroundColor2);
  text-align: left;
  border: none;
  height: 4vh;
  border-radius: 5px;
}

.new-sla-meta-data-parent-side{
    width: 0vw;
}


.new-sla-meta-data-parent .form-container{
    width: 21vw;
    height: 100%;
    padding: 0vw 1vw 1vw 1vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.new-sla-meta-data-parent input{
    font-size: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor);
    text-align: center;
    border: none;
    color: var(--textColor);
    width: 19vw;
    height: 4vh;
    border-radius: 5px;
    margin-bottom: 1vh;
}
.new-sla-meta-data-parent button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #feb64c;
    text-align: center;
    border: none;
    color: #fff;
    width: 19vw;
    height: 4vh;
    border-radius: 5px;
    margin-left: auto;
}
.new-sla-meta-data-parent button:hover{
    opacity: .5;
}
.new-sla-meta-data-parent select{
    font-size: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor);
    text-align: center;
    border: none;
      color: var(--textColor);
    width: 19vw;
    height: 4vh;
    border-radius: 5px;
    margin-bottom: 1vh;
}
.new-sla-meta-data-parent .label-metadata-1{
    width: 19vw;
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--textColor);
    font-size: 0.8vw;
    font-weight: bold;
    padding-left: 7%;
}
.new-sla-meta-data-parent .label-metadata-2{
    width: 19vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--textColor);
    font-size: 0.8vw;
    font-weight: normal;
    margin-bottom: 1vh;
}
.new-sla-meta-data-parent input[type="radio"]{
    display: flex;
    background-color: var(--backgroundColor);
    text-align: center;
    border: none;
      color: var(--textColor);
    width: 1vw;
    margin:0;
    padding: 0;
    font-size: 0.8vw;
}
.new-sla-meta-data-parent .agreement-type-entry-parent{
  height: auto;
  padding: 1vw 1vw 0vw 1vw;
  width: 21vw;
}

.new-sla-meta-data-parent .radio-button-parent-tab-type{
  display: flex;
  flex-direction: row;
  height:4vh;
  width: 19vw;
  justify-content: space-between;
  background-color: var(--backgroundColor);
  border-radius: 5px;
  margin-bottom: 1vh;
}
.radio-button-parent-tab-type .checkboxParent{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
}
.radio-button-parent-tab-type .checkboxParent label{
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.radio-button-parent-tab-type .checkboxParent:nth-child(1){
  border-radius: 0px 5px 0px 0px;
}
.radio-button-parent-tab-type .checkboxParent:nth-child(4){
  border-radius: 5px 0px 0px 0px;
}
.radio-button-parent-tab-type .active{
  background-color: var(--backgroundColor2);
}
.radio-button-parent-tab-type input[type="radio"]{
  display: none;
}
.new-sla-meta-data-parent .radio-button-parent{
    display: flex;
    flex-direction: row;
    height:auto;
    width: 19vw;
    justify-content: space-around;
    background-color: var(--backgroundColor);
    border-radius: 5px;
    margin-bottom: 1vh;
}
.radio-button-parent:nth-child(2){
  margin-bottom:2vh ;
}

.radio-button-parent .checkboxParent{
  display: flex;
  flex-direction: row;
  align-items: center;
}