.dashboard-container{
	height: 92%;
    width: 100%;
	display: flex;
	flex-direction: column;
}
.chart-select-tab{
	height: 5%;
}
.dashboard-component-1{
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	margin: 1rem 0rem 0rem 1rem;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	height: min-content;
	box-shadow: 3px 3px 2px var(--boxShadowColor);
	width: 97%;
	height: 29vh;
}
.dashboard-component-1-2{
	width: 100%;
	height: 95%;
}
.center-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.dashboard-component-2{
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	margin: 1rem 0rem 0rem 1rem;
	padding: 0.5rem;
	height: min-content;
	width: 97%;
	box-shadow: 3px 3px 2px var(--boxShadowColor);
}
.dashboard-component-2-1{
	width: 100%;
	height: 25.8vh;
	overflow-x: auto;
}
.dashboard-component-2-1::-webkit-scrollbar {
	width: 8px;
  }
  
.dashboard-component-2-1::-webkit-scrollbar-track {
	background-color: #e4e4e4;
	border-radius: 100px;
}
  
.dashboard-component-2-1::-webkit-scrollbar-thumb {
	background-color: #4d4d4f;
	border-radius: 100px;
}
.dashboard-component-3{
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	margin: 1rem 0rem 0rem 1rem;
	padding: 0.5rem;
	height: min-content;
	width: 97%;
	height: 35vh;
	box-shadow: 3px 3px 2px var(--boxShadowColor);
}
.dashboard-component-3-1{
	width: 100%;
	height: 85%;
}
.dashboard-component-4{
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	margin: 1rem 0rem 0rem 1rem;
	padding: 0.5rem;
	height: min-content;
	width: 97%;
	height: 50%;
	box-shadow: 3px 3px 2px var(--boxShadowColor);
}
.dashboard-component-4-1{
	width: 100%;
	height: 90%;
}
.dashboard-component-5{
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	margin: 1rem 0rem 0rem 1rem;
	padding: 0.5rem;
	height: min-content;
	width: 97%;
	height: 68%;
	box-shadow: 3px 3px 2px var(--boxShadowColor);
}
.dashboard-component-5-1{
	width: 100%;
	height: 95%;
}
.dashboard-component-6{
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	margin: 1rem 0rem 0rem 1rem;
	padding: 0.5rem;
	height: min-content;
	width: 97%;
	height: 32%;
	box-shadow: 3px 3px 2px var(--boxShadowColor);
}
.dashboard-component-6-1{
	width: 100%;
	height: 95%;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.dashboard-component-6-1-1{
	width: 50%;
	height: 90%;
	display: flex;
	flex-direction: column;
}
.dashboard-component-6-1-2{
	width: 50%;
	height: 90%;
	display: flex;
	flex-direction: column;
}