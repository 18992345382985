.navigation-container{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
    flex: row;
    width: 90%;
}

.navigation-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .navigation-root .logo-box {
    width: 2.5vw;
    height: fit-content;
    border-radius: 7px;
    background-color: var(--backgroundColor3)!important;
    padding: 1vh .5vw;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navigation-root .logo-box .logo-box-ico{
    width: 100%;
    height: 100%;
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }
  .navigation-root .info-box{
    display: flex;
    flex-flow: row wrap;
    margin-right: auto;
    height: 5vh;
    align-items: center;
  }
  .navigation-root .text-box {
    padding-left: 1vw;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }
  .navigation-root .text-box label{
      line-height: 1.5vh;
  }
  .navigation-root .search-item{
    width: 15vw;
    height: 4vh;
    border-radius: 10px;
    padding: 0 1vw;
    border: none;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
  }
  