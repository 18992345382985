.parent-edit-user {
  height: 98%;
  display: flex;
  width: 30%;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .parent-edit-user .formbold-form-wrapper {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    padding: 0vw 0vw 0vh 0vw;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    box-shadow: 3px 3px 2px var(--boxShadowColor);
  }

  .parent-edit-user .formbold-form-wrapper .to-new-user-button{
    height: 4%;
    width: 3vw;
    background-color: var(--backgroundColor3)!important;
    color:#ffffff;
    display: flex;
    margin-left: auto;
    border: none;
    border-radius: 0px 10px 0px 10px;
    justify-content: center;
    align-items: center;
  }
  .parent-edit-user .formbold-form-wrapper .to-new-user-button .close-button-ico{
    width: 1vw;
    height: fit-content;
  }
  .parent-edit-user .formbold-form-wrapper .to-new-user-button:hover{
    opacity: .5;
  }
  .parent-edit-user .formbold-form-wrapper form{
    height: 96%;
    display: flex;
    flex-direction: column;
    padding: 0vw 1.5vw 2vh 1.5vw;
    overflow: auto;
  }
  .parent-edit-user .formbold-form-title h2 {
    font-weight: 600;
    font-size: 1.2vw;
    color: #feb64c;
  }
  .parent-edit-user .formbold-form-title p {
    font-size: 0.7vw;
    color: var(--textColor);
  }
  .parent-edit-user .formbold-input-flex {
    display: flex;
    gap: 1vw;
  }
  .parent-edit-user .formbold-input-flex > div {
    width: 100%;
  }
  .parent-edit-user .formbold-form-input {
    text-align: center;
    width: 100%;
    height: 4vh;
    border-radius: 5px;
    border: 1px solid #e9eeff;
    background: #e9eeff;
    font-weight: 500;
    font-size: 0.7vw;
    color: var(--textColor);
    outline: none;
    resize: none;
    overflow: hidden;
  }
  .parent-edit-user .formbold-form-input:focus {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }
  .parent-edit-user .formbold-form-label {
    color: var(--textColor);
    font-size: 0.7vw;
    display: block;
    margin-top: 1vh;
  }
  .parent-edit-user .formbold-btn {
    font-size: 0.8vw;
    border-radius: 5px;
    border: none;
    font-weight: normal;
    display: flex;
    background-color: #feb64c;
    color: white;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    font-size: 0.8vw;
    margin-top: 2vh;
    margin-bottom: 2vh;
    min-height: 4vh;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .parent-edit-user .formbold-btn:hover {
    opacity: .5;
  }

  .show-password-span{
    position: absolute;
    right: .5vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .parent-edit-user .errorLabel{
    overflow-wrap: anywhere;
    color: red;
    font-size: 0.6vw;
  }