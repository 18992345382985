.company-group-box-container{
    width: 20vw;
    min-width: 20vw;
    height: min-content;
    display: flex;
    flex-direction: column;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    color: var(--textColor);
    font-size: .8vw;
}
.company-group-box-container .group-owner-container{
    height: 4vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8vw;
    font-weight: 400;
    color: var(--tableHeaderColor);
    cursor: pointer;
}

.company-group-box-container .group-owner-container .group-info {
    flex: 1; /* Bu, mevcut boş alanı kaplamasını sağlar */
    text-align: center; /* Metni .group-info içinde ortalar */
  }
  
  .company-group-box-container .group-owner-container .button-container {
    margin-left: auto; /* Bu, silme simgesini sağa doğru iteler */
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
  }

  .company-group-box-container .group-owner-container img{
    width: 1vw;
    height: fit-content;
  }


.company-group-box-container .group-companies-container{
    height: min-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    padding: 1vh 0 1vh 0 
}
.company-group-box-container .group-companies-container .company{
    height: 4vh;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    background-color: var(--backgroundColor);
    border-radius: 5px;
}
.company-group-box-container .group-companies-container .company:hover{
    background-color: var(--tableRowHover);
}
.company-group-box-container .group-companies-container .company label{
    width: 70%;
    cursor: pointer;
    overflow: hidden;
}
.company-group-box-container .group-companies-container .company .button-container{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}
.company-group-box-container .group-companies-container .company .button-container img {
    width: 1vw;
    height: fit-content;
}
.company-group-box-container .group-companies-container .company .button-container img:hover {
    opacity: .5;
    cursor: pointer;
}