.login-parent{
    width: 100vw;
    height: 100vh;
    background-color: var(--backgroundColor);
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-parent .container{
    width: 55%;
    height: 40%;
      background-color: var(--backgroundColor2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vh 3vw 2vh 3vw;
    /* -webkit-box-shadow: 5px 5px 0px -0px #0E1119;
    -moz-box-shadow: 5px 5px 0px -0px #0E1119; */
    box-shadow: -2px 2px 2px 2px rgb(0,0,0,0.1);
}
.login-parent .container label:first-child{
    color: #5383C8;
    font-size: 1vw;
    font-weight: 400;
    margin-bottom: 1vh;
}
.login-parent .container label:nth-child(2){
    color: var(--textColor);
    font-size: 0.7vw;
    font-weight: 400;
    margin-bottom: 1vh;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center;
}
.login-parent .container .container-2{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    width: 90%;
}
.login-parent .container .form-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    margin-right: 1vw;
}
.login-parent .container .form-container .login-input{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor);
    text-align: center;
    border: none;
    color: var(--textColor);
    width: 63%;
    height: 17%;
    border-radius: 5px;
    margin-bottom: 1vh;
    font-size: 0.7vw;
}
.login-parent .container .form-container button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor);
    text-align: center;
    border: none;
      color: var(--textColor);
;
    width: 63%;
    height: 17%;
    border-radius: 5px;
    margin-bottom: 1vh;
    background-color: #B6EBB7;
    font-size: 0.9vw;

}
.login-parent .container .form-container button:hover{
    opacity: .5;
    
}
.login-parent .container .form-container button:last-child{
    display: flex;
    align-items: left;
    justify-content: left;
      background-color: var(--backgroundColor2);
    text-align: left;
    border: none;
      color: var(--textColor);
;
    width: 63%;
    height: 10%;
    border-radius: 5px;
    margin-bottom: 1vh;
    font-size: 0.7vw;
}
.login-parent .container .lang-logo-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  align-items: center;
}
.login-parent .container .lang-logo-container .lang-container{
  width: 20%;
}
.login-loader {
    border: 0.15vw solid #f3f3f3; /* Light grey */
    border-top: 0.15vw solid #3498db; /* Blue */
    border-radius: 50%;
    width: 1vw;
    height: 1vw;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .login-pages-img{
    width: 13vw;
  }
  .login-exp-logo{
    width: 8vw;
  }
  .login-parent .errorLabel{
    overflow-wrap: anywhere;
    color: red;
    font-size: 0.6vw;
    display: flex;
    align-items: left;
    justify-content: left;
      background-color: var(--backgroundColor2);
    text-align: left;
    border: none;
    width: 63%;
    height: 10%;
    border-radius: 5px;
    margin-bottom: 1vh;
  }