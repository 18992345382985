.container-dashboard-info-box{
    display: flex;
    height: 20%;
}
.container-dashboard-info-box .parent-dib{
    display: flex;
    flex-direction: row wrap;
    width: 100%;
    height: 100%;
}
.container-dashboard-info-box .infobox-dashboard{
    width: 4vw;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    
    background-color: var(--backgroundColor3)!important;
    color: white;
    border-radius: 80px;
    font-weight: 900;
    font-size: 0.8vw;
}
.container-dashboard-info-box .parent-dib h5{
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8vw;
      color: var(--textColor);

    font-weight: bold;
}