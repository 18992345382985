.new-service-parent {
  width: 100%;
  height: 93vh;
  display: flex;
  flex-direction: column;
}

.new-service-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: auto;
  gap: 1vw;
}