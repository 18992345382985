.all-kpis-detail-container {
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1vh 1vw 0% 1vw;
}
.all-kpis-detail-container-part1 {
  width: 100%;
  height: 10%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
}
.all-kpis-detail-container-part1 .close-kpi-detail-ico{
  width: 1vw;
  height: fit-content;
  margin-left: auto;
  cursor: pointer;
}
.all-kpis-detail-container-part1 .close-kpi-detail-ico:hover{
  opacity: .5;
}
.all-kpis-detail-container-part1-column {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-wrap: break-Word;
  line-height: 1;
  background-color: var(--backgroundColor);
  padding: 1vh 2vw;
}
.all-kpis-detail-container-part1-column-item {
    color: var(--textColor);
  text-align: center;
  overflow-wrap: break-Word;
  font-size: 0.7vw;
}
.all-kpis-detail-container-part1-column-item:nth-child(2) {
  border-radius: 5px;
  padding: 3% 0%;
  min-width: 5vw;
}

.all-kpis-detail-container-part1-column-edit-button-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.all-kpis-detail-container-part1-column-edit-button {
  border: none;
  height: 30%;
  background-color: transparent;
}

.all-kpis-detail-container-part1-column-edit-button:hover {
  opacity: 0.5;
}

.all-kpis-detail-container-part2 {
  width: 100%;
  height: 90%;
  background-color: var(--backgroundColor2);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.all-kpis-detail-container table {
  width: 100%;
  height: 100%;
    background-color: var(--backgroundColor2);
  display: flex;
  flex-direction: column;
}

.all-kpis-detail-container thead {
  height: 6vh;
  min-height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.all-kpis-detail-container thead tr {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.all-kpis-detail-container thead th {
  text-align: left;
  padding-left: 1%;
  font-weight: 200;
  font-size: .8vw;
  color: var(--tableHeaderColor);

}

.all-kpis-detail-container tbody td:nth-child(1),
.all-kpis-detail-container thead th:nth-child(1) {
  width: 12%;
}
.all-kpis-detail-container tbody td:nth-child(2),
.all-kpis-detail-container thead th:nth-child(2) {
  width: 12%;
}
.all-kpis-detail-container tbody td:nth-child(3),
.all-kpis-detail-container thead th:nth-child(3) {
  width: 11%;
}
.all-kpis-detail-container tbody td:nth-child(4),
.all-kpis-detail-container thead th:nth-child(4) {
  width: 10%;
}
.all-kpis-detail-container tbody td:nth-child(5),
.all-kpis-detail-container thead th:nth-child(5) {
  width: 15%;
}
.all-kpis-detail-container tbody td:nth-child(6),
.all-kpis-detail-container thead th:nth-child(6) {
  width: 10%;
}
.all-kpis-detail-container tbody td:nth-child(7),
.all-kpis-detail-container thead th:nth-child(7) {
  width: 20%;
}
.all-kpis-detail-container tbody td:nth-child(8),
.all-kpis-detail-container thead th:nth-child(8) {
  width: 10%;
}


.all-kpis-detail-container tbody {
  height: auto;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.all-kpis-detail-container tbody tr {
    background-color: var(--backgroundColor);
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 1vh;
  min-height: 4vh;
  cursor: default;
}

.all-kpis-detail-container tbody tr:hover {
  background-color: var(--tableRowHover);
  transition-delay: 0s;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: line;
}

.all-kpis-detail-container tbody tr td {
  color: var(--textColor);
  display: flex;
  width: 10%;
  font-size: 0.7vw;
  padding-left: 1%;
}
.all-kpis-detail-tbody .triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}

.all-kpis-detail-tbody .triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}

.all-kpis-detail-tbody .square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}
