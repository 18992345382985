.small-profile-card-parent-my-user {
  width: 70%;
  height: 98%;
  padding: 0;
}
.small-profile-card-parent-my-user .parent-small-profile-card {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-color: #eee;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 2vh 1vw;
  flex-direction: column;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}