.container-profile-card {
    background-color: var(--backgroundColor2);
    top: 7vh;
    right: 0%;
    width: 20vw;
    height: 93vh;
    box-shadow: -3px 5px 10px var(--boxShadowColor);
    position: absolute;
    z-index: 500;
    box-sizing: border-box;
}   

.container-profile-card-close {
    background-color: var(--backgroundColor2);
    top: -100vh;
    right: 0%;
    width: 20vw;
    height: 92.9vh;
    box-shadow: -3px 5px 10px var(--boxShadowColor);
    position: absolute;
    z-index: 500;
}

.parent-profile-card {
    height: 100%;
    padding: 2vh 2vw 7vh 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parent-profile-card .profile-photo-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    border-color: #D0D6E2;
    margin-bottom: 2vh;
}
.parent-profile-card .user-status{
    color: #79C47B;
    margin-bottom: 3vh;
    font-size: .6vw;
    font-weight: 400;
}
.parent-profile-card .info-container {
    width: 100%;
    height: 2vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.parent-profile-card .info-container label {
    color: var(--textColor);
    font-weight: 200;
    font-size: .8vw;    
}
.parent-profile-card .info-container-img {
    width: 100%;
    height: 4vh;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.parent-profile-card .info-container-img label {
    color: var(--textColor);
    font-weight: 200;
    font-size: .8vw;    
}
.parent-profile-card .info-container-img img{
    width: 1.2vw;
    height: fit-content;
    display: flex;
    margin-right: 1vw;
}


.parent-profile-card .logout-ico-profile-card {
    width: 1.2vw;
    height: fit-content;
    margin-right: .5vw;
}

.parent-profile-card .logout-button-parent-profile-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
}

.parent-profile-card button:hover {
    opacity: 0.5;
}

.parent-profile-card .logout-button-parent-profile-card label {
    color: #255CAE;
    font-weight: 600;
    font-size: 1vw;
    cursor: pointer;
}
.parent-profile-card .lang-container{
    width: 50%;
    display: flex;
    margin-top: 1vh;
    justify-self: start;
}