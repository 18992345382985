*{
    margin: 0;
    padding: 0;
}
.historical-data-update-check-container{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20%;
    height: 50%;
    background-color: var(--backgroundColor3);
    left: 40%;
    top: 25%;
    border-radius: 10px;
    z-index: 999;
    box-sizing: border-box;
    padding: 2vh 1vw;
}
.historical-data-update-check-container .check-result{
    height: 70%;
    width: 100%;
    overflow-y: auto;   
}
.historical-data-update-check-container .check-result .result-item{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;

}
.historical-data-update-check-container .check-result .result-item .content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.historical-data-update-check-container .check-result .changing-result-container{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    color: #fff;
    align-items: center;
    justify-content: space-around;
}
.historical-data-update-check-container .check-result .result-item label{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: .8vw ;
}
.historical-data-update-check-container .process-container{
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10%;
}
.historical-data-update-check-container hr{
    margin: 0;
    padding: 0;
    border-color: #fff; 
    border-radius: 10px;
}
.historical-data-update-check-container .check-result .result-item .right-arrow-ico{
    width: 15%;
    filter: invert(100%);
    -webkit-filter: invert(100%);
}
.triangle-up {
    width: 0;
    height: 0;
    border-left: 1.5vh solid transparent;
    border-right: 1.5vh solid transparent;
    border-bottom: 1vw solid #57bd84;
  }
  
  .triangle-down {
    width: 0;
    height: 0;
    border-left: 1.5vh solid transparent;
    border-right: 1.5vh solid transparent;
    border-top: 1vw solid #bd5757;
  }
  
  .square {
    height: 1vh;
    width: 1.5vw;
    background-color: #555;
  }

  .historical-data-update-check-container .button-container{
    display: flex;
    height: 30%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
  }
  .historical-data-update-check-container .confirm-button{
      border: none;
      width: 50%;
      height: 100%;
      background-color: rgb(102, 173, 90);
      color: #fff;
      border-radius: 10px;
  
  }
  .historical-data-update-check-container button:hover{
      opacity: .5;
  }
  
  .historical-data-update-check-container .close-button{
    border: none;
    width: 50%;
    height: 100%;
    background-color: rgb(219, 78, 82);
    color: #fff;
    border-radius: 10px;
  }

  .historical-data-update-check-container .process-container label{
    height: 50%;
    width: 100%;
    color: #fff;
    font-size: .8vw;
    text-align: center;
}