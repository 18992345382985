.user-answer-box-parent{
    box-sizing: content-box;
    display: flex; 
    justify-content: flex-end;
}
.user-answer-box{
    display: inline-block;
    box-sizing: content-box;
    width: 50%;
    padding:0.5vh 1vw 0.5vh 1vw;
    margin-top: 15px;
      color: var(--textColor);

    border-radius: 20px;
      background-color: var(--backgroundColor);
    width: auto;
    font-size: 0.7vw;
}
.robot-img{
margin-top: auto;
}