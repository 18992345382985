.counter-box-container{
    width: 20%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    box-sizing: border-box;
    gap: 1vw;
    padding: 1.5vh 1vw;
}
.counter-box-container #counter-ico{
    width: 2vw;
    -webkit-filter: invert(0%);
    filter: invert(0%);
}
.counter-box-container .count-number{
    font-weight: 300;
    font-size: 1.5vw;
    color: var(--tableHeaderColor);
    width: 40%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    line-height: 1;
}
.counter-box-container .count-text{
    line-height: 1;
    font-weight: 400;
    font-size: .9vw;
    color: #000;
    color: var(--tableHeaderColor);
}
.counter-box-container .ico-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}
@media (max-width: 1200px) {

}
@media (max-width: 900px) {

}
@media (max-width: 600px) {
    
}
@media (max-width: 300px) {

}
