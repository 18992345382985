.settings-main-parent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    gap: 5%;

}
.settings-main-parent .settings-menu-container{
    width: 25%;
    height: 100%;
    display: flex;
}
.settings-main-parent .settings-frame-container{
    width: 75%;
    height: 100%;
    display: flex;
}