.settings-menu-parent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.settings-menu-parent .settings-menu-button{
    border: none;
    height: 4vh;
    width: 100%;
    background-color: var(--backgroundColor);
    border-radius: 10px;
    font-size: .8vw;
    font-weight: 400;
    position: relative;
    text-transform: uppercase;
}
.settings-menu-parent .settings-menu-button:hover{
    background-color: var(--backgroundColor2);
}   
.settings-menu-parent .active{
    background-color: var(--backgroundColor2);
    font-weight: 600;
}
.settings-menu-parent .active::before{
    background: var(--backgroundColor3);
    border-radius: 10px;
    content: "";
    height: var(--base-size-24, 1.5rem);
    left: calc(var(--base-size-8, 0.5rem)*-1);
    position: absolute;
    top: calc(50% - 12px);
    width: .2vw;
}