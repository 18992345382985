.releated-agreements-container{
    position: absolute;
    right: 5%;
    top: 0px;
    width: 25vw;
    height: 82vh;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    padding: 2vh 1vw;
    z-index: 998;
    display: flex;
    flex-direction: column;
    overflow: auto;
    animation: slideRight .5s ease-in-out;
}
.releated-agreements-container .table-information{
    width: 100%;
    min-height: 5%;
    display: flex;
}
.releated-agreements-container .table-information label{
    width: 90%;
    font-size: .8vw;
    font-weight: 600;
    color: var(--tableHeaderColor);
    word-wrap:break-word;
    

}
.releated-agreements-container .table-information img{
    width: 1vw;
    height: fit-content;
    margin-left: auto;
    cursor: pointer;
}
.releated-agreements-container table{
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
}
.releated-agreements-container table thead{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.releated-agreements-container table thead tr{
    width: 100%;
    height: 4vh;
    display: flex;
    flex-direction: row;
}
.releated-agreements-container table thead  tr th{
    color: var(--tableHeaderColor);
    font-size: .8vw;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding-left: .5vw;
}
.releated-agreements-container table tbody{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.releated-agreements-container table tbody tr{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--backgroundColor);
    margin-bottom: 1vh;
    border-radius: 5px;
}
.releated-agreements-container table tbody tr:hover{
    background-color: var(--tableRowHover);
}
.releated-agreements-container table tbody  tr td{
    color: var(--textColor);
    font-size: .8vw;
    font-weight: 200;
    display: flex;
    align-items: center;
    padding-left: .5vw;
    min-height: 4vh;
}
.releated-agreements-container table thead tr th:nth-child(1),
.releated-agreements-container table tbody tr td:nth-child(1)
{
    width: 60%;
}
.releated-agreements-container table thead tr th:nth-child(2),
.releated-agreements-container table tbody tr td:nth-child(2)
{
    width: 40%;
}

@keyframes slideRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}