ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.menu-container img{
  width: 1.5vw;
  height: auto;
  margin-right: 1vw;
}

.menu-container {
  width: 100%;
  width: 8vw;
  user-select: none;
  position: relative;
  user-select: none;
  border-radius: 5px;
}

.menu-container .menu-trigger {
  padding: 1vh 1vw;
  height: 4vh;
  width:100%;
	background: var(--backgroundColor);
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  color: var(--textColor);
  font-size: .8vw;

}



.dropdown-menu {
  position: absolute;
  top: 4.5vh;
  background-color: var(--backgroundColor2);
  border-radius: 5px;
  width:100%;
}



.dropdown-menu ul li {
  padding: 1vh 1vw;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}



.dropdown-menu ul li:hover button {
  color: rgb(212, 33, 9);
  cursor: pointer;
  background: transparent;
  border: none;
}



.dropdown-menu ul li:hover img {
  opacity: 1;
  cursor: pointer;
}



.dropdownItem {
  display: flex;
}



.dropdownItem img {
  opacity: 0.5;
  transition: var(--speed);
}


.dropdown-menu button {
  transition: var(--speed);
  background-color: transparent;
  border: none;
  background: transparent;
  color: var(--textColor);
  font-size: .8vw;
}