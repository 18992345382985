.parent-container-kpis {
  width: 65%;
  height: 82vh;
  display: flex;
}


.container-kpis tbody {
  display: block;
  width: 100%;
  height: 76vh;
}
.container-kpis-side tbody{
  height: 34vh;
}

.container-kpis thead {
  display: flex;
  width: 100%;
  min-height: 6vh;
}


.container-kpis tbody tr {
  display: flex;
  flex-direction: row;
  width: 98%;
  margin-bottom: 1vh;
  border-radius: 5px;
  background-color: var(--backgroundColor);
  min-height: 4vh;
}

.container-kpis thead tr {
  display: flex;
  flex-direction: row;
  width: 98%;
  border-radius: 5px;
  background-color: var(--backgroundColor2);
}


.container-kpis tbody {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: #eee;

}

.container-kpis td {
  font-size: 0.7vw;
  color: var(--textColor);
  text-align: center;
  overflow-x: hidden;
  padding-left: 1%;
}

.container-kpis {
  text-align: left;
  background-color: var(--backgroundColor2);
  border-radius: 0px 0px 5px 5px;
  min-height: 77.8vh;
  height: 77.8vh;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  display: flex;
  flex-direction: column;
  padding-left: 2%;
}

.container-kpis-side {
  height: 36vh;
  min-height: 36vh;
}
.container-kpis-side-2 {
  min-height: 72vh;
  height: 72vh;
}

.container-kpis th {
  font-weight: 400;
  font-size: 0.8vw;
  color: var(--tableHeaderColor);
  background-color: var(--backgroundColor2);
  text-align:left;
  padding-left: 1%;
}

.container-kpis td,
.container-kpis th {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}


.container-kpis td:nth-child(1),
.container-kpis th:nth-child(1) {
  width: 25%;
}

.container-kpis td:nth-child(2),
.container-kpis th:nth-child(2) {
  width: 20%;
}

.container-kpis td:nth-child(3),
.container-kpis th:nth-child(3) {
  width: 50%;
}

.container-kpis td:nth-child(4),
.container-kpis th:nth-child(4) {
  width: 10%;
}




.container-kpis tr:hover {
  background-color: var(--tableRowHover);
  transition-delay: 0s;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: line;
}

@media (max-width: 800px) {

  .container-kpis td:nth-child(4),
  .container-kpis th:nth-child(4) {
    display: none;
  }
}



.triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}

.square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}

.customer-name {
  width: max-content;
  box-sizing: border-box;
  font-size: 1.2vw;
  text-align: left;
  font-weight: 800;
  margin: 1%;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: auto;
}

.customer-name-slas-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  border-style: solid;
  padding: 1vh 2vw;
  border-color: #000;
  border-width: 1px;
  border-radius: 5px;
}

.kpi-detail-parent {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  border-radius: 5px;
}

.kpi-detail-parent-side {
  margin-top: 50%;
}

#selectDateForKpis {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F3F6FD;
  text-align: center;
  border: none;
  color: var(--textColor);
  width: 100%;
  min-height: 3vh;
  border-radius: 5px 5px 0px 0px;
  border-bottom: #e9eeff;
  border-bottom-style: solid;
  border-width: 0.1vh;
  font-size: 0.7vw;
  background-color: var(--backgroundColor2);
}

.kpis-chart-parent {
  display: flex;
  flex-direction: column;
  width: 12%;
  height: 80%;

}



.kpis-chart {
  width: 100%;
  height: 95%;
  margin-bottom: 1%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
    background-color: var(--backgroundColor2);
  padding-top: 10%;
  padding-bottom: 10%;
}

.kpis-chart-side {
  height: 0%;
}

.yearly-trend-parent-box-kpis {
  display: flex;
  flex-flow: column;
  height: max-content;
  gap: 1rem;
  width: 100%;

}

.yearly-trend-parent-box-kpis .trend-container {
  display: flex;
  flex-direction: column;
    color: var(--textColor);
;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 0.7vw;
  width: 100%;

}

.yearly-trend-parent-box-kpis .month {
  text-align: center;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 5px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

}

.yearly-trend-parent-box-kpis .month:hover {
  background-color: var(--tableRowHover);
    color: var(--textColor);
  font-weight: normal;
  font-size: 1vw;
  border-radius: 5px;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: all;
  transition-timing-function: line;
}

.yearly-trend-parent-box-kpis .month-triangle-up {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-bottom: 1vw solid #57bd84;
}

.yearly-trend-parent-box-kpis .month-triangle-down {
  width: 0;
  height: 0;
  border-left: 1.5vh solid transparent;
  border-right: 1.5vh solid transparent;
  border-top: 1vw solid #bd5757;
}

.yearly-trend-parent-box-kpis .month-square {
  height: 1vh;
  width: 1.5vw;
  background-color: #555;
}

.kpis-chart-parent .sla-trend-label {
  text-align: center;
  color: var(--textColor);
  font-weight: bold;
  height: 5%;
  font-size: 1vw;
}

.add-kpi-button {
  width: 10vw;
  height: 3vh;
  position: absolute;
  right: 2%;
  top: 1.2vh;
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .5vw;
  border: none;
  background-color: transparent;
  font-size: .7vw;
  border-style: solid;
  padding: .7vh 2vw;
  border-radius: 10px;
  border-width: 1px;
  color: var(--tableHeaderColor);
}

.add-kpi-button:hover {
  opacity: .5;
}
.add-kpi-img {
  width: fit-content;
  height: 100%;
  margin-right: .5vw;
}

.add-kpi-box-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  border-radius: 5px 5px 0px 0px;
  background-color: var(--backgroundColor2);
  padding: 0vh 2% ;
  justify-content: space-between;
  min-height: 4.2vh;
  padding-left: 2vw;
}

.add-kpi-box-parent .input-part {
  height: 3vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.2vh;
}

.add-kpi-box-parent .info-part {
  width: 100%;
  display: flex;
  justify-content: space-between;
    background-color: var(--backgroundColor);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.add-kpi-box-parent .info-part label {
  display: flex;
  width: 45%;
  height: 100%;
  color: var(--textColor);
  font-size: .8vw ;
  justify-content: center;
  align-items: center;
}

.add-kpi-box-parent input[type="text"] {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  border-style: none;
  border-color: #000;
  text-align: center;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  margin-right: 10vw;
}

.add-kpi-box-parent .add-kpi-cancel {
  width: 1vw;
  height: 60%;
  content: url("../assets/error.png");
}

.add-kpi-box-parent .add-kpi-cancel:hover {
  opacity: .5;
}

.add-kpi-box-parent .add-kpi-ok {
  width: 1vw;
  height: fit-content;
  cursor: pointer;
}

.add-kpi-box-parent .add-kpi-ok:hover {
  opacity: .5;
}

.add-kpi-box-parent .search-kpi {
  content: url("../assets/search.png");
}

.add-kpi-box-parent .search-kpi:hover {
  opacity: .5;
}