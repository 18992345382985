.boxbox .input-new-category {
    border: 1px solid var(--backgroundColor3);
    border-radius: 20px;
    cursor: pointer;
    padding: 0.5vh 0;
    margin: 0;
    background: transparent;
    color: #feb64c;
    min-width: 60%;
    max-width: 100%;
    text-align: center;
    line-height: 100%;    
    font-size: .7vw;
    margin-top: 2vh;
    margin-left: 20%;
    margin-right: 20%;
}
.boxbox .input-new-category:focus {
    color: var(--textColor);
    outline: none;
}
.boxbox .input-new-category:hover {
    color: var(--textColor);
    background-color: #feb64c;
}
.chatbot-background {
    height: 100vh;
    width: 100vw;
    background-position-x: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.chatbot-container{
    display: flex;
    flex-direction: row;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    height: 82vh;
    max-height: 82vh;
  }

  .chatbot-container .arrow-button-chatbot{
    background-color: transparent;
    width: 3%;
    margin-left: 1%;
    margin-right: 1%;
    border: none;
  }
  .chatbot-container .arrow-button-chatbot:hover{
    opacity: 0.5;
  }
  
  .chatbot-container .arrow-button-chatbot img{
    width: 80%;
}


.boxbox {
    width: 100%;
    height: 86%;
    color: #fff;
    border-radius: 20px;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
    scroll-snap-align: end;
    padding-left: 1vw;
    padding-right: 1vw;

}


.logo {
    height: 35%;
    width: 25%;
}
