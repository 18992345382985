.warnings-table-parent{
    display: flex;
    width: 100%;
    height: 100%;
}
.warnings-table-parent table{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.warnings-table-parent thead{
    display: flex;
    width: 100%;
}
.warnings-table-parent tbody{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1vh;
    overflow-y: auto;
}
.warnings-table-parent tr{
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    min-height: 4vh;
    padding-left: 1vw;
    padding-right: 1vw;
}
.warnings-table-parent tbody tr{
    background-color: var(--backgroundColor);
}
.warnings-table-parent tbody tr:hover{
    background-color: var(--tableRowHover);
}
.warnings-table-parent td{
    display: flex;
    color: var(--textColor);
    width: 25%;
    font-size: .7vw;
}
.warnings-table-parent th{
    display: flex;
    color: var(--textColor);
    width: 25%;
    font-size: .8vw;
    font-weight: 200;
    flex-direction: row;
}