.sign-main-parent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    padding: 1vw;
    align-items: center;
}
.sign-main-parent .table-header {
    width: 100%;
    height: 10%;
    text-align: center;
    color: var(--tableHeaderColor);
    font-weight: 400;
    font-size: .8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sign-main-parent .signature-comp-container{
    width: 100%;
    height: 90%;
    display: flex;
}