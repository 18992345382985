* {
    margin: 0;
    padding: 0;
}

.edit-sla-parent {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 90;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, .5);
}
.edit-sla-parent .table-header{
    width: 100%;
    text-align: center;
    text-align: center;
    color: var(--tableHeaderColor);
    font-weight: 400;
    font-size: .8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-sla-parent .edit-sla-container {
    background-color: var(--backgroundColor);
    height: 100%;
    width: 100%;
    position: relative;
    padding: 1vw 1vw 1vw 1vw;
    animation: slideRight .5s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.edit-sla-parent .button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:1vw
}

.edit-sla-parent .close-button {
    height: 3vh;
    width:15vw;
    border-radius: 10px;
    border: none;
    background-color: var(--cancelColor);
    color: var(--textColor2);
    font-size: .8vw;
}

.edit-sla-parent .confirm-button {
    height: 3vh;
    width: 20%;
    border-radius: 10px;
    border: none;
    background-color: var(--confirmColor);
    color: var(--textColor2);
    font-size: .8vw;

}

.edit-sla-parent .close-button:hover {
    opacity: .5;
}

button:hover {
    opacity: .5;
}

.edit-sla-parent .form-container {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row;
    gap: 1vw;
}



.edit-sla-parent .edit-service-file{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    gap: 2%;
}

.edit-sla-parent .edit-info {
    display: flex;
    width: 33%;
    height: 100%;
}
.edit-sla-parent .edit-file {
    display: flex;
    width: 33%;
    height: 100%;
}
.edit-sla-parent .edit-services {
    display: flex;
    width: 33%;
    height: 100%;
}

.edit-sla-parent .sign-container{
    width: 100%;
    height: 35%;
    display: flex;
}
