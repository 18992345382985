.parent-new-user {
  height: 98%;
  display: flex;
  width: 30%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.parent-new-user .formbold-form-wrapper {
  height: 100%;
  margin: 0 auto;
  width: 100%;
  padding: 4% 1.5vw 2vh 1.5vw;
  background-color: var(--backgroundColor2);
  border-radius: 10px;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  color: var(--textColor);
}
.parent-new-user .formbold-form-wrapper form{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.parent-new-user .formbold-form-title h2 {
  font-weight: 600;
  font-size: 1.2vw;
  color: #feb64c;
}
.parent-new-user .formbold-form-title p {
  font-size: 0.7vw;
  color: var(--textColor);
  margin-top: 1vh;
}
.parent-new-user .formbold-input-flex {
  display: flex;
}
.parent-new-user .formbold-input-flex > div {
  width: 100%;
}
.parent-new-user .formbold-desc-container{
  margin-top: 1vh;
  width: 100%;
  font-size: .6vw;
  color: var(--textColor);
  background-color: var(--backgroundColor);
  border-radius: 5px;
  padding: 1vh 1vw;
  margin-bottom: 1vh;
}

.parent-new-user .formbold-form-input {
  text-align: center;
  width: 100%;
  padding: 1vh 2vw;
  border-radius: 5px;
  border: 1px solid #e9eeff;
  background: var(--backgroundColor);
  font-weight: 500;
  font-size: 0.7vw;
  color: var(--textColor);
  outline: none;
  resize: none;
  overflow: hidden;
  height: 4vh;
}
.parent-new-user .formbold-form-input:focus {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.parent-new-user .formbold-form-label {
  color: var(--textColor);
  font-size: 0.7vw;
  display: block;
  margin-top: 1vh;
}
.parent-new-user .formbold-btn {
  font-size: 0.8vw;
  border-radius: 5px;
  border: none;
  font-weight: normal;
  display: flex;
  background-color: #feb64c;
  color: white;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  font-size: 0.8vw;
  height: 4vh;
  min-height: 4vh;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.parent-new-user .formbold-btn:hover {
  opacity: .5;
}

.parent-new-user .errorLabel{
  overflow-wrap: anywhere;
  color: red;
  font-size: 0.6vw;
}

.show-password-span{
  position: absolute;
  right: .5vw;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}