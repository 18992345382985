.alert-container {
    display: flex;
    flex-flow: column;
    width: 18vw;
    height: 6vh;
    z-index: 999;
    border-radius: 5px;
    margin-bottom: 2vh;
    -webkit-transition:margin-bottom .5s, height .5s;
    transition: margin-bottom .5s, height .5s;  
    overflow-y: hidden;
    
  }
  .alert-container-header{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.7vw;
    padding-left: 1vw;
  }

  .info {
    background-color: #EEE;
    border: 0.2vh solid #DDD;
    color: #999;
  }
  
  .success {
    background-color: #EFE;
    border: 0.2vh solid #DED;
    color: #9A9;
  }
  
  .notice {
    background-color: #EFF;
    border: 0.2vh solid #DEE;
    color: #9AA;
  }
  
  .warning {
    background-color: #FDF7DF;
    border: 0.2vh solid #FEEC6F;
    color: #C9971C;
  }
  
  .error {
    background-color: #FEE;
    border: 0.2vh solid #EDD;
    color: #A66;
  }