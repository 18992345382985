.signing-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
}
.signing-container .users-table-container::-webkit-scrollbar {
    width: 2px!important;
}
.signing-container .users-table-container{
    width: 50%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
.signing-container .completed-sign{
    height: 80%;
}
.signing-container .completed{
    position: absolute;
    bottom: 0;
    left: 22.5%;
    transform: translate(-50%,0%);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .8vw;
    font-weight: 600;
    color: var(--confirmColor);
}

.signing-container .completed img{
    width: 2vw;
    height: fit-content;
}

.signing-container .users-table-container hr{
    border-width:.1px;
    border-color: gray;
}
.signing-container .users-table-container hr:last-child{
    display: none;
}
.signing-container .users-table-container .verifying-users-item{
    display: flex;
    flex-direction: row;
    gap: .5vw;
    width: 100%;
    height: 3vh;
    min-height: 3vh;
    align-items: center;
    padding-right: 1vw;
}
.signing-container .users-table-container .verifying-users-item .user-info{
    font-size: .8vw;
    color: var(--textColor);
    width: 40%;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}
.signing-container .users-table-container .verifying-users-item .box{
    color: var(--textColor2);
    font-size: .7vw;
    border-radius: 10px;
    background-color: var(--confirmColor);
    padding-left: 1vw;
    padding-right: 1vw;
    height: 2vh;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;   
}
.signing-container .users-table-container .verifying-users-item .not{
    background-color: var(--cancelColor);
}
.signing-container .users-table-container .verifying-users-item .owner{
    background-color: #feb64c;
}

.signing-container .users-table-container .verifying-users-item .remove-verify{
    width: 1.4vw;
    height: fit-content;
    cursor: pointer;
}
.signing-container .users-table-container .verifying-users-item .remove-verify:hover{
    opacity: .5;
}
.signing-container .counter-number{
    color: var(--confirmColor);
    position: absolute;
    top: -3vh;
    left: 0;
    z-index: 999;
    font-size: 1vw;
    font-weight: 500;
}
