.pop-up-alert-parent{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    background-color: rgb(0, 0, 0, .3);
}

.pop-up-alert-container{
    position: absolute;
    left: 35vw;
    top: 35vh;
    width: 30vw;
    height: 30vh;
    background-color: var(--backgroundColor3);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    animation: slideRight .5s ease-in-out;
    z-index: 999;
}

@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}
.pop-up-alert-parent .ico-part{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;
    width: 100%;
}

.pop-up-alert-parent .ico-part .ico-part-img{
    display: flex;
    width: fit-content;
    height: 100%;
}
.pop-up-alert-parent .explain-part{
    display: flex;
    height: 50%;
    width: 100%;
    background-color: var(--backgroundColor2);
}
.pop-up-alert-parent .explain-part .pop-up-text{
    width: 100%;
    height: 100%;
    color: var(--textColor);
    font-size: .8vw;
    font-weight: 400;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

}
.pop-up-alert-parent .button-part{
    display: flex;
    flex-direction: row;
    height: 25%;
    width: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 0px 0px 9px 9px;
    justify-content: space-around;
    align-items: center;
}
.pop-up-alert-parent .button-part button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 40%;
    height: 50%;
    border-radius: 5px;
    border: none;
    background-color: var(--confirmColor);
    color: var(--textColor2);
    font-size: .8vw;
}
.pop-up-alert-parent .button-part button:first-child{
    background-color: var(--cancelColor);
}