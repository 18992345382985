:root {
  --containerCustomerWidth: 90%;
  --containerCustomerMarginRight: 5%;
  --parent-small-profile-card-margin-right:5%;
  --parent-small-profile-card-width:90%;
}

.parent-container-customers {
    width: 100%;
    height: 93%;
    overflow-y: hidden;
    overflow-x: hidden;
    font-size: 0.7vw;

}

.customers-general-parent{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.customers-general-container{
  width: 100%;
  height: 100%;
}

.customers-general-container-side{
  width: 80%;
  height: 100%;
  --containerCustomerWidth: 93%;
  --containerCustomerMarginRight: 2%;
  --parent-small-profile-card-margin-right:3%;
  --parent-small-profile-card-width:93%;
}

.header-1 {
  line-height: 1em;
  color: var(--textColor);
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 0%;
  font-weight: bold;
  text-align: center;
}

.container-customers {
  text-align: left;
  width: var(--containerCustomerWidth);
  margin-left: 5%;
  margin-right: var(--containerCustomerMarginRight);
  height: 82vh;
  background-color: var(--backgroundColor2);
  border-radius: 5px;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  display: flex;
  flex-direction: column;
}
.container-customers-side {
  height: 40%;
}

.container-customers tbody { 
  display: flex;
  flex-direction: column; 
  width: 100%;
  padding-right: 1vw;
  padding-left: 1vw;
 }
 .container-customers thead { 
  display: block; 
  width: 100%;
  padding-right: 1vw;
  padding-left: 1vw;
  background-color: var(--backgroundColor2);
  border-radius:  10px 10px 0px 0px;
 }

.container-customers tbody tr {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1vh;
    border-radius: 5px;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    min-height: 4vh;
    cursor: pointer;
}
.container-customers thead tr {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
  background-color: var(--backgroundColor2);
  height: 6vh;
}

.container-customers tbody{
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: #eee;
    height: 76vh;
}

.container-customers th {
  font-weight: 400;
    font-size: .8vw;
    color: var(--tableHeaderColor);
    display: flex;
    align-items: center;
    padding-left: 1%;
}

.container-customers td {
    font-weight: 200;
    display: flex;
    padding-left: 1%;
    align-items: center;
    color: var(--textColor);
}

.container-customers td:nth-child(1),
.container-customers th:nth-child(1) {
  width: 40%;
}
.container-customers td:nth-child(2),
.container-customers th:nth-child(2) {
  width: 40%;
}
.container-customers td:nth-child(3),
.container-customers th:nth-child(3) {
  width: 20%;
  display: flex;
  flex-direction: row-reverse;
}

.container-customers tbody tr:hover {
  background-color: var(--tableRowHover);
  transition-delay: 0s;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: line;
}

.customerButton{
  width: 20%;
  margin-right: 20%;
}

.customerButton:hover{
  opacity: 0.5;
}

.statisticButton{
    height: fit-content;
    width: 1.2vw;
    margin-right: 1vw;
}

.statisticButton:hover{
  opacity: 0.5;
}

.parent-container-customers .statistic-parent{
  width: 20%;
  height: 82vh;
  margin-top: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.parent-container-customers .statistic-parent-side{
  width: 0%;
  height: 100%;
}

.parent-container-customers .statistic-container{
  width: 95%;
  height: 100%;
  background-color: var(--backgroundColor2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 2vh 0;
}

.parent-container-customers .statistic-container .chart-container{
  width: 100%;
  height: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parent-container-customers .statistic-container .chart-container:nth-child(2){
  height: 25%;
}

.parent-container-customers .statistic-container .chart-parent{
  width: 90%;
  height: 90%;
  background-color: var(--backgroundColor2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.parent-container-customers .statistic-container .chart-parent:nth-child(0){
  margin-top: -20vh;
}

.statistic-container label{
  color: var(--textColor);
  font-size: .8vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* İçeriği kesip "..." ile göstermek isterseniz */
  line-height: 1;
  height: 10%;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.statistic-parent .rigth-arrow-statistics{
  display: flex;
  position: absolute;
  left: 1vw;
  top: 1.5vh;
  height: 2vh;
  width: fit-content;
  cursor: pointer;
}

.statistic-parent .rigth-arrow-statistics:hover{
  opacity: .5;
}
.parent-container-customers .selected-comp-label{
  font-size: .8vw;
  color: var(--textColor);
  font-weight: 200;
  margin-right: 1vw;
}
.parent-container-customers select{
  width: 10vw;
  height: 4vh;
  border-radius: 10px;
  margin-right: 1vw;
  background-color: var(--backgroundColor2);
  color: var(--textColor); 
}