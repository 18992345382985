.progress-bar-container{
    width: 100%;
    border-radius: 10px;
    height: 1.5vh;
    display: flex;
    flex-direction: row;
}
.progress-bar-container .part-1{
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    background-color: var(--backgroundColor3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: .75vw;
}
.progress-bar-container .part-2{
    text-align: center;
    color: var(--textColor);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
    background-color: var(--backgroundColor);
    font-size: .75vw;
}