.user-bar-parent{
    width: 100%;
    background-color: var(--backgroundColor2);
    height: 7vh;
    z-index: 160;
    min-height: 7vh;
}
.user-bar-parent .page-style{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 2rem;
    height: 100%;
    gap: 2vw;

}

.user-bar-parent .arrow-ico-userbar {
    height: fit-content;
    width: .8vw;
    filter: invert(50%);
}
.user-bar-parent .arrow-ico-userbar:hover{
    opacity: 0.5;
}
.user-bar-parent .logo-userbar {
    height: fit-content;
    width: 1.5vw;
}
.user-bar-parent .logo-userbar:hover{
    opacity: 0.5;
}
.user-bar-parent .notification-userbar {
    height: 3.25vh;
    width: 1.5vw;
    margin-left: 1rem;
}
.user-bar-parent .notification-userbar:hover{
    opacity: 0.5;
}
.user-bar-parent .icons-userbar{
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
}