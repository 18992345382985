.parent-container-failedSlaList {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header-1 {
    line-height: 1em;
    color: var(--textColor);
    margin-top: 0%;
    margin-bottom: 0%;
    padding: 0%;
    font-weight: bold;
    text-align: center;
  }

  .container-failedSlaList {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 95%;
    border-radius: 5px;
    height: 100%;
    margin: 0;
  }
  
  .container-failedSlaList tbody {
    display: block;
    width: 100%;
    padding-right: 4%;
    padding-left: 4%;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: #eee;
    height: 90%;
  }
  
  .container-failedSlaList thead {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-right: 4%;
    padding-left: 4%;
    height: 10%;
  }
  
  .container-failedSlaList tbody tr {
    display: block;
    width: 100%;
    margin-bottom: 1vh;
    border-radius: 7px;
    background-color: #ffc048;
  }
  
  .container-failedSlaList thead tr {
    display: block;
    width: 100%;
    margin-bottom: 1vh;
    border-radius: 7px;
  }
  
  
  .container-failedSlaList td {
    font-weight: normal;
    font-size: 1em;
    -webkit-box-shadow: 0 0px 0px -0px #0e1119;
    -moz-box-shadow: 0 0px 0px -0px #0e1119;
    box-shadow: 0 0px 0px -0px #0e1119;
    overflow-x: hidden;
    font-size: 0.7vw;
  }
  

  
  .container-failedSlaList th h1 {
    font-weight: bold;
    font-size: 1em;
      color: var(--textColor);

  }
  
  .container-failedSlaList td,
  .container-failedSlaList th {
    text-align: center;
    display: inline-block;
    padding-bottom: 1%;
    padding-top: 1%;
    padding-left: 2%;
  }
  
  .container-failedSlaList th {
    padding-bottom: 1.5%;
    padding-top: 1.5%;
    font-size: 0.8vw;
  }
  
  
  .container-failedSlaList td:nth-child(1),
  .container-failedSlaList th:nth-child(1) {
      color: var(--textColor);
;
    width: 100%;
  }
  

  
  
  .container-failedSlaList tr:hover {
    background-color: #ffd17b;
    font-weight: bold;
    border-radius: 8px;
    transition-delay: 0s;
    transition-duration: .5s;
    transition-property: all;
    transition-timing-function: line;
  }
  .container-failedSlaList tbody .error {
    background-color: #ff4848;
  }
  .container-failedSlaList tbody .error:hover {
    background-color: #ff7b7b;
  }
  
  @media (max-width: 800px) {
    .container-failedSlaList td:nth-child(4),
    .container-failedSlaList th:nth-child(4) {
      display: none;
    }
  }
  .container-all-kpis-detail {
    width: 100%;
    height: 85%;
    display: flex;
  }
  