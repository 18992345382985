.forecast-selection-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recommendation-result-parent {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 1.5vh 0vw 1.5vh 0vw;
    color: var(--textColor);
    align-items: center;
    height: min-content;
    justify-content: space-evenly;
    width: 95%;
    gap: 1vw;
}

.recommendation-result-container {
    display: flex;
    flex-direction: column;
    justify-self: center;
    gap: 1vw;
    height: 100%;
}

.recommendation-result-container label {
    border-radius: 5px;
    padding: 0 .5vw 0 .5vw;
    height: 5vh;
    display: flex;
    align-items: center;
    min-width: 15vw;
    white-space: nowrap;
    width: min-content;
    background-color: var(--backgroundColor);
    font-size: .7vw;
    transition: all 0s!important;
    -webkit-transition: all 0s!important;
    -webkit-transition: all 0s!important;
    transition: background-color .5s, color .5s!important;
    -webkit-transition: background-color .5s, color .5s!important;
}

.recommendation-result-container label:hover {
    border-style: solid !important;
    border-width: .1vw;
}

.recommendation-result-container label select {
    min-width: 40%;
    font-size: .7vw;
    border-radius: 5px;
    background-color: var(--backgroundColor2)!important;
    border: none;
    padding-left: 0.25vw;
    color: var(--textColor);
}
.forecast-selection-container .active {
    border-style: solid !important;
    border-width: .1vw;
}
.arimaModel {
    display: inline-block;
    cursor: pointer;
    position: relative;
    cursor: pointer;
    color: #000000;
    box-sizing: border-box;
    font-style: normal;
    transition:all .25s ease-in-out;
    margin-top: .5vh;
    margin-left: auto;
    
  }
  .arimaModel:before {
    content: attr(data-tip);
    font-size: 14px;
    position: absolute;
    background: rgba(10, 20, 30, 0.85);
    color: #fff;
    line-height: 1.2em;
    padding: 0.5em;
    font-style: normal;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    min-width: 20vw;
    max-width: 20vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    letter-spacing: 0;
    font-weight: 600;
    white-space: normal;
    
  }
  .arimaModel:after {
    width: 0;
    height: 0;
    border-style: solid;
    content: '';
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    
    
  }
  .arimaModel:hover:before,
  .arimaModel:hover:after {
    visibility: visible;
    opacity: 1
  }
  .arimaModel:before {
      bottom: 150%;
      left: 50%;
      box-sizing: border-box;
      border-radius: 3px;
      margin-left: -10vw;
    }
  .arimaModel:after {
      position: absolute;
      top: -50%; 
      left: 50%;
      margin-left: -.4vw;
      border-width: 8px;
      border-style: solid;
      border-color: rgba(10, 20, 30, 0.85) transparent  transparent  transparent;
  }
    
  .prophetModel {
    display: inline-block;
    cursor: pointer;
    position: relative;
    cursor: pointer;
    color: #000000;
    box-sizing: border-box;
    font-style: normal;
    transition:all .25s ease-in-out;
    margin-top: .5vh;
    margin-left: auto;
  
  }
  .prophetModel:before {
    content: attr(data-tip);
    font-size: 14px;
    position: absolute;
    background: rgba(10, 20, 30, 0.85);
    color: #fff;
    line-height: 1.2em;
    padding: 0.5em;
    font-style: normal;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    min-width: 20vw;
    max-width: 20vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    letter-spacing: 0;
    font-weight: 600;
    white-space: normal;
  
  }
  .prophetModel:after {
    width: 0;
    height: 0;
    border-style: solid;
    content: '';
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out
  }
  .prophetModel:hover:before,
  .prophetModel:hover:after {
    visibility: visible;
    opacity: 1
  }
  
  .prophetModel:before {
      bottom: 150%;
      left: 50%;
      box-sizing: border-box;
      border-radius: 3px;
      margin-left: -10vw;
    }
  .prophetModel:after {
      position: absolute;
      top: -50%; 
      left: 50%;
      margin-left: -.4vw;
      border-width: 8px;
      border-style: solid;
      border-color: rgba(10, 20, 30, 0.85) transparent  transparent  transparent;
  }
  .exponentialModel {
    display: inline-block;
    cursor: pointer;
    position: relative;
    cursor: pointer;
    color: #000000;
    box-sizing: border-box;
    font-style: normal;
    transition:all .25s ease-in-out;
    margin-top: .5vh;
    margin-left: auto;
  
  }
  .exponentialModel:before {
    content: attr(data-tip);
    font-size: 14px;
    position: absolute;
    background: rgba(10, 20, 30, 0.85);
    color: #fff;
    line-height: 1.2em;
    padding: 0.5em;
    font-style: normal;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    min-width: 20vw;
    max-width: 20vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    letter-spacing: 0;
    font-weight: 600;
    white-space: normal;
  
  }
  .exponentialModel:after {
    width: 0;
    height: 0;
    border-style: solid;
    content: '';
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out
  }
  .exponentialModel:hover:before,
  .exponentialModel:hover:after {
    visibility: visible;
    opacity: 1
  }
  
  .exponentialModel:before {
      bottom: -60%;
      left: -100%;
      box-sizing: border-box;
      border-radius: 3px;
      margin-left: -20vw;
    }
  .exponentialModel:after {
      position: absolute;
      top: 0%; 
      left: -50%;
      margin-left: -.5vw;
      border-width: 8px;
      border-style: solid;
      border-color:  transparent  transparent  transparent rgba(10, 20, 30, 0.85);
  }