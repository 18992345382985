.edit-sla-services-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor2);
    border-radius: 10px;
    padding: 1vw 1vw;
}
.edit-sla-services-container .service-list-table-parent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    overflow: hidden;
}
.edit-sla-services-container .table-header{
    width: 100%;
    text-align: center;
    text-align: center;
    color: var(--tableHeaderColor);
    font-weight: 400;
    font-size: .8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-sla-services-container .add-service-button{
    width: 5vw;
    height: 2vh;
    border-radius: 10px;
    border: none;
    background-color: var(--backgroundColor2);
    color: var(--textColor);
    font-size: .7vw;
    margin-left: auto;
}
.edit-sla-services-container table{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.edit-sla-services-container thead{
    display: flex;
    width: 100%;
}
.edit-sla-services-container tbody{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1vh;
    overflow-y: auto;
}
.edit-sla-services-container tr{
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    padding-left: 1vw;
    padding-right: 1vw;
    height: 3vh;
}
.edit-sla-services-container tbody tr{
    background-color: var(--backgroundColor);
}
.edit-sla-services-container tbody tr:hover{
    background-color: var(--tableRowHover);
}
.edit-sla-services-container th{
    display: flex;
    color: var(--textColor);
    width: 75%;
    font-size: .8vw;
    font-weight: 200;
}
.edit-sla-services-container th:last-child{
    width: 25%;
}
.edit-sla-services-container .file-list-table-parent th{
    width: 80%
}
.edit-sla-services-container .file-list-table-parent th:last-child{
    width: 20%
}
.edit-sla-services-container td{
    display: flex;
    color: var(--textColor);
    width: 75%;
    font-size: .7vw;
    overflow: hidden;
    height: 100%;
    align-items: center;
}
.edit-sla-services-container td:last-child{
    width: 25%;
}