.engine-parent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.engine-parent .button-container{
    display: flex;
    width: 100%;
    height: 5vh;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    gap: 1vw;
  }
  .engine-parent .confirm-button{
    border: none;
    width: 15vw;
    height: 5vh;
    background-color: var(--confirmColor);
    color: #fff;
    border-radius: 10px;

}
.engine-parent .confirm-button:hover{
    opacity: .5;
}

.engine-parent .close-button{
  border: none;
  width: 15vw;
  height: 5vh;
  background-color: var(--cancelColor);
  color: #fff;
  border-radius: 10px;

}
.engine-parent .close-button:hover{
    opacity: .5;
}
.engine-container {
    width: 100%;
    height: 36vh;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: var(--backgroundColor2);

    -webkit-box-shadow: 3px 3px 5px 0px rgb(190, 190, 190);
    -moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190);
    box-shadow: 3px 3px 5px 0px rgba(190, 190, 190);
}

.section-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 15%;
    background-color: var(--backgroundColor4);
    border-radius: 5px 5px 0px 0px;
}
.section-container .section{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
}
.section-container .section input[type="radio"]{
    display: none;
}
.section-container .selected {
    background-color: var(--backgroundColor2)!important;
    font-weight: 900;
}
.section-container label{
    color: var(--textColor);
}
.operation-frame-container{
    width: 100%;
    height: 85%;
}