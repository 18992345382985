.node__root > circle {
  fill: red;
}

.node__branch > circle {
  fill: yellow;
}

.node__leaf > circle {
  fill: green;
}

button{
    color: var(--textColor);
}
button:hover{
    opacity: 1;
}

.confirmOrganizationAdd:hover,
.cancelOrganizationAdd:hover,
.confirmPositionAdd:hover,
.cancelPositionAdd:hover
{
    cursor: pointer;
    opacity: .5;
}
