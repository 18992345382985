.parent-container-all-kpis-slas {
  width: 40%;
  height: 100%;
}


.header-1 {
  line-height: 1em;
  color: #fff;
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 0%;
  font-weight: bold;
  text-align: center;
}

.container-all-kpis-slas tbody {
  display: block;
  width: 100%;
  padding-right: 4%;
  padding-left: 4%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: #eee;
  height: 87.5%;
}

.container-all-kpis-slas thead {
  display: block;
    background-color: var(--backgroundColor2);
  border-radius: 10px;
  width: 100%;
  padding-top: 1vw;
  padding-right: 4%;
  padding-left: 4%;
  height: 10%;
}

.container-all-kpis-slas tbody tr {
  display: block;
  width: 100%;
  margin-bottom: 0.75rem;
  border-radius: 7px;
  background-color: var(--backgroundColor);
}

.container-all-kpis-slas thead tr {
  display: block;
  width: 100%;
  margin-bottom: 0.75rem;
  border-radius: 7px;
  background-color: var(--backgroundColor2);
}


.container-all-kpis-slas td {
  font-weight: normal;
  font-size: 1em;
  -webkit-box-shadow: 0 0px 0px -0px #0e1119;
  -moz-box-shadow: 0 0px 0px -0px #0e1119;
  box-shadow: 0 0px 0px -0px #0e1119;
}

.container-all-kpis-slas {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 97%;
  display: table;
    background-color: var(--backgroundColor2);
  border-radius: 5px;
  height: 100%;
  margin: 0;
}

.container-all-kpis-slas th h1 {
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  color: var(--textColor);

}

.container-all-kpis-slas td,
.container-all-kpis-slas th {
  display: inline-block;
  padding-bottom: 1%;
  padding-top: 1%;
  padding-left: 2%;
}

.container-all-kpis-slas th {
  padding-bottom: 1.5%;
  padding-top: 1.5%;
  background-color: var(--backgroundColor2);
  font-size: 0.8vw;
}

.container-all-kpis-slas td {
  overflow-x: hidden;
  font-size: 0.7vw;

}

.container-all-kpis-slas td:nth-child(1),
.container-all-kpis-slas th:nth-child(1) {
  width: 50%;
}

.container-all-kpis-slas td:nth-child(2),
.container-all-kpis-slas th:nth-child(2) {
  width: 50%;
}

.container-all-kpis-slas td:first-child {
  color: var(--textColor);
}

.container-all-kpis-slas td:nth-child(2) {
  color: var(--textColor);
}

.container-all-kpis-slas tr:hover {
  background-color: var(--tableRowHover);
  font-weight: bold;
  border-radius: 8px;
  transition-delay: 0s;
  transition-duration: .5s;
  transition-property: all;
  transition-timing-function: line;
}

@media (max-width: 800px) {
  .container-all-kpis-slas td:nth-child(4),
  .container-all-kpis-slas th:nth-child(4) {
    display: none;
  }
}
.container-all-kpis-detail {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 3px 3px 2px var(--boxShadowColor);
  border-radius: 5px;
}
.container-all-kpis-detail .table-info-tab{
  width: max-content;
  position: relative;
  display: flex;
  height: 7.5%;
  width: 70%;
  border-radius: 5px 5px 0px 0px;
  top: -7.5%;
  right: -27%;
  color: var(--textColor);
  align-items: center;
  font-size: .7vw;
  background-color: var(--backgroundColor2);
  padding: .5vw;
  border-style: solid;
  border-width: .1vw;
  align-items: center;
  justify-content: center;
  display: none;
}
.container-all-kpis-detail .table-info-tab:hover{
  opacity: 0;
}
