.parent-container-RecentTransactions {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
  }
  
  body {
    font-family: "Open Sans", sans-serif; 
    font-weight: 300;
    line-height: 1.42em;
    color: #a7a1ae;
    background-color: #1f2739;
  }
  
  .header-1 {
    line-height: 1em;
    color: #fff;
    margin-top: 0%;
    margin-bottom: 0%;
    padding: 0%;
    font-weight: bold;
    text-align: center;
  }

  .container-RecentTransactions {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 95%;
    border-radius: 5px;
    height: 95%;
    margin: 0;
  }
  
  .container-RecentTransactions tbody {
    display: block;
    width: 100%;
    padding-right: 4%;
    padding-left: 4%;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: #eee;
    height: 95%;
  }
  
  .container-RecentTransactions thead {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--textColor);
    border-radius: 10px;
    width: 100%;
    padding-right: 4%;
    padding-left: 4%;
    height: 5%;
  }
  
  .container-RecentTransactions tbody tr {
    display: block;
    width: 100%;
    margin-bottom: 0.75rem;
    border-radius: 7px;
    background-color: var(--backgroundColor2);
  }
  
  .container-RecentTransactions thead tr {
    display: block;
    width: 100%;
    margin-bottom: 0.75rem;
    border-radius: 7px;
    color: var(--textColor);

  }
  
  
  .container-RecentTransactions td {
    font-weight: normal;
    font-size: 1em;
    -webkit-box-shadow: 0 0px 0px -0px #0e1119;
    -moz-box-shadow: 0 0px 0px -0px #0e1119;
    box-shadow: 0 0px 0px -0px #0e1119;
  }
  

  
  .container-RecentTransactions th h1 {
    font-weight: bold;
    font-size: 1em;
    text-align: left;
      color: var(--textColor);

  }
  
  .container-RecentTransactions td,
  .container-RecentTransactions th {
    display: inline-block;
    padding-bottom: 1%;
    padding-top: 1%;
    padding-left: 2%;
  }
  
  .container-RecentTransactions th {
    padding-bottom: 1.5%;
    padding-top: 1.5%;
    font-size: 0.8vw;
  }
  
  .container-RecentTransactions td {
    overflow-x: hidden;
    font-size: 0.7vw;
  
  }
  
  .container-RecentTransactions td:nth-child(1),
  .container-RecentTransactions th:nth-child(1) {
      color: var(--textColor);
;
    width: 25%;
  }
  
  .container-RecentTransactions td:nth-child(2),
  .container-RecentTransactions th:nth-child(2) {
      color: var(--textColor);
;
    width: 30%;
  }

  .container-RecentTransactions td:nth-child(3),
  .container-RecentTransactions th:nth-child(3) {
      color: var(--textColor);
;
    width: 45%;
  }
  
  
  .container-RecentTransactions tr:hover {
    background-color: #dce4ff;
    font-weight: bold;
    border-radius: 8px;
    transition-delay: 0s;
    transition-duration: .5s;
    transition-property: all;
    transition-timing-function: line;
  }
  
  @media (max-width: 800px) {
    .container-RecentTransactions td:nth-child(4),
    .container-RecentTransactions th:nth-child(4) {
      display: none;
    }
  }
  .container-all-kpis-detail {
    width: 100%;
    height: 85%;
    display: flex;
  }
  