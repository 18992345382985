.select-company-group-container{
    display: flex;
    flex-direction: row;
    margin-left: auto;
}
.select-company-group-container select{
    min-width: 10vw;
    height: 4vh;
    border-radius: 10px;
    background-color: var(--backgroundColor2);
    color: var(--textColor); 
    margin-right: 1vw;
}
.select-company-group-container .select-comp-label{
    font-size: .8vw;
    color: var(--textColor);
    font-weight: 200;
    margin-right: 1vw;
    display: flex;
    align-items: center;
}