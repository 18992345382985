.my-users-parent {
  width: 100%;
  height: 93vh;
  display: flex;
  flex-direction: column;
}
.my-users-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 84vh;
  margin-left: 5%;
  margin-right: 5%;
  gap: 1vw;
}
.my-users-parent .selected-comp-label{
  font-size: .8vw;
  color: var(--textColor);
  font-weight: 200;
  margin-right: 1vw;
}

.my-users-parent .newCompany{
  background-color: var(--backgroundColor2);
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 4vh;
  line-height: 1;
  font-size: .7vw;
  font-weight: 200;
  margin-right: 1vw;
}
.my-users-parent .newCompany:hover{
  background-color: var(--goldColor);
  opacity: 1;
}
.my-users-parent .newCompany img {
  margin-right: 1vw;
  width: 1vw;
  height: fit-content;
}

.my-users-parent select{
  width: 20%;
  height: 4vh;
  border-radius: 10px;
  margin-right: 1vw;
  background-color: var(--backgroundColor2);
  color: var(--textColor); 
}